import { LOADER_POSITION, Utils } from '../../services/utils/utils';
import { CartService } from 'src/services/cart-service/cart-service';
import { LocalStorage } from 'src/services/local-storage/local-storage';
import { Analytics } from '../../services/analytics/analytics';
import { _$, Window } from '../../main';
import { DetailCart } from '../../pages/detail-cart/detail-cart';
import { LockerUtils } from '../../services/utils/locker-utils';

export class ProductCard {

    scaleMap = {
        "MG": "mg",
        "CG": "cg",
        "GR": "g",
        "HG": "hg",
        "KG": "kg"
    }

    private heavySurchargeTimer;
    
    constructor(
        private _utils: Utils,
        private cartService : CartService,
        private localStorage: LocalStorage,
        private _analytics: Analytics,
        private _detailCart: DetailCart,
        private _lockerUtils: LockerUtils
    ) { 
    }
   
    nkOnInit() {
    } 

    handleClick(e: object) {
        let size = _$(e).attr('data-scale');
        var card = _$(e).parents(".component-ProductCard");
        /* Menù aperto per cancellare le selezioni precedenti */
        if(card.find(".weight-list").hasClass("remove-selections")){
            this.hideVarWeight(e);
            this.callAddRemoveToCartNew(e, 'remove', 'variable', parseFloat(size), 'g');
        }
        else{
            this.hideVarWeight(e);
            this.callAddRemoveToCartNew(e, 'add', 'variable', parseFloat(size), 'g');
        }
    }

    toggleFeedbackOverlay(e: object) {
        var card = _$(e).parents(".component-ProductCard");
        let oldFeedback = card.find(".feedback-overlay-pcard");
        let newFeedback = card.find(".feedback-overlay-pcard").clone();
        oldFeedback.before(newFeedback);
        oldFeedback.remove();
        newFeedback.css('display', 'block');
        setTimeout(function(){
            newFeedback.css('display', 'none');
        }, 3000);
    }

    updateSearchQuery(e: object){
        let query = JSON.parse(_$(e).attr('data-product')).query;
        this.localStorage.setItem('searchQuery', JSON.stringify(query));
    }

    addToCart(e: object){
        if (!Window.typeOfService){
            this._utils.showModalWhenNotLogged();
        }
        else{
            let dataProduct = JSON.parse(_$(e).closest('.component-ProductCard').attr('data-product'));
            /* Prodotto a peso incrementale */
            if(dataProduct.increment){
                this.callAddRemoveToCartNew(e, 'add', 'incremental');  
            }
            //se l'oggetto è di tipo prodotto a peso variabile
            else if (dataProduct.variations){
                this.showVarWeight(e);   
            }
            //Se l'oggetto è di tipo prodotto pesante
            else if(dataProduct.volume){
                this.callAddRemoveToCartNew(e, 'add', 'heavy');
            }else{
                this.callAddRemoveToCartNew(e, 'add', 'base');
            }
        }
    }

    removeToCart(e: object){
        if (!Window.typeOfService){
            this._utils.showModalWhenNotLogged();
        }else{
            let dataProduct = JSON.parse(_$(e).closest('.component-ProductCard').attr('data-product'));
            /* Prodotto a peso incrementale */
            if(dataProduct.increment){
                this.callAddRemoveToCartNew(e, 'remove', 'incremental'); 
            }
            //se l'oggetto è di tipo prodotto a peso variabile
            else if (dataProduct.variations){
                this.showVarWeightRemove(e);   
            }
            //Se l'oggetto è di tipo prodotto pesante
            else if(dataProduct.volume){
                this.callAddRemoveToCartNew(e, 'remove', 'heavy');
            }else{
                this.callAddRemoveToCartNew(e, 'remove', 'base');
            }
        }
    }

    showVarWeight(e: object){
        var card = _$(e).parents(".component-ProductCard");
        card.find(".var-weight-header-title").html("Aggiungi quantità");
        card.find(".weight-list").removeClass("remove-selections");
        card.find(".weight-list .uk-tag").removeClass("disabled");
        card.find(".weight-list .uk-tag").removeClass("active");
        this.animateShowMenu(e);
    }

    hideVarWeight(e: object){
        var card = _$(e).parents(".component-ProductCard");
        this.animateHideMenu(e);
    }

    showVarWeightRemove(e: object){
        var card = _$(e).parents(".component-ProductCard");
        card.find(".var-weight-header-title").html("Elimina quantità");
        card.find(".weight-list").addClass("remove-selections");
        card.find(".var-weight-menu").removeClass("uk-hidden");
        let scales = Object.keys(JSON.parse(card.attr('data-quantity')))
        card.find(".weight-list .uk-tag").removeClass('disabled');
        /* Nel caso in cui i data-scale da SAP siano ad una cifra decimale, ci vorrà un controllo */
        let scalesFloat = [];
        for(let i = 0; i < scales.length; i++){
            scalesFloat[i] = parseFloat(scales[i]);
        }
        
        for(let i = 0; i < card.find(".weight-list .uk-tag").length; i++){
            if(scalesFloat.indexOf(parseFloat(card.find(".weight-list .uk-tag")[i].getAttribute('data-scale'))) < 0){
                _$(card.find(".weight-list .uk-tag")[i]).addClass('disabled');
            }
        }
        this.animateShowMenu(e);
    }

    animateShowMenu(e: object){
        var card = _$(e).parents(".component-ProductCard");
        card.find(".var-weight").removeClass("uk-hidden");
        /* Reflow del documento per via della rimozione dell'uk-hidden */
        const reflow = card.height;
        var menu = card.find(".var-weight");
        const listener = () => {
            menu.removeClass("uk-animation-slide-bottom");
            menu.removeClass("uk-animation-fast");
            menu.off('animationend', listener);
        };
        menu.on("animationend", listener);
        menu.removeClass("uk-animation-reverse");
        menu.addClass("uk-animation-slide-bottom");
        menu.addClass("uk-animation-fast");
    }

    animateHideMenu(e: object){
        var card = _$(e).parents(".component-ProductCard");
        var menu = card.find(".var-weight");
        const listener = () => {
            menu.removeClass("uk-animation-slide-bottom");
            menu.removeClass("uk-animation-reverse");
            menu.removeClass("uk-animation-fast");
            menu.addClass("uk-hidden");
            menu.off('animationend', listener);
        };
        menu.on("animationend", listener);
        menu.addClass("uk-animation-slide-bottom");
        menu.addClass("uk-animation-fast");
        menu.addClass("uk-animation-reverse");
    }

    callAddRemoveToCartNew(e, type: string, productType: string, size? : number, unit?: string){
        let productCard = _$(e).closest('.component-ProductCard');
        let dataProduct = JSON.parse(productCard.attr('data-product'));
        let promoAlertNeeded;
        let contingency;
        let activePromo;
        if(productType == 'variable') {
            this._utils.showLoader(_$(e), true, LOADER_POSITION.BUTTON_ADDCART_FROM_TAG);
        } else {
            this._utils.showLoader(_$(e), true, LOADER_POSITION.BUTTON);
        }

        let success = (res) => {

            let response = res.data;
            this.localStorage.setItem('flagFirstAddToCart', this._utils.checkFirstAddToCartFlag(response.data));
            if(productType == 'variable') {
                this._utils.showLoader(_$(e), false, LOADER_POSITION.BUTTON_ADDCART_FROM_TAG);
            } else {
                this._utils.showLoader(_$(e), false, LOADER_POSITION.BUTTON);
            }
            let statusCode = response.data.modifications.cartModifications[0].statusCode;
            
            if(statusCode == "success"){
                _$(productCard).find('.add-quantity .add-quantity-button:last-child').prop('disabled', false);
                this._utils.manageLowStock(dataProduct.code, false);
                let prezzo;
                switch(productType){
                    case 'variable':
                        this.toggleFeedbackOverlay(e);
                        if(response.data.modifications.cartModifications[0].quantity < 1){
                            let dataQuantity = JSON.parse(productCard.attr('data-quantity'));
                            let dataEntryNumber = JSON.parse(productCard.attr('data-entryNumber'));
                            this._utils.updateRemovedProductOnPage(productType, dataProduct.code, size.toString());
                            delete dataQuantity[size];
                            delete dataEntryNumber[size];
    
                            /* Pezzature svuotate */
                            if(Object.keys(dataQuantity).length < 1){
                                this.hideAlert(productCard.find('.alert-add-to-cart'));
                                this.hideAddRemoveMenu(e);
                                productCard.find('.add-quantity').find('.quantity').html('0 '+ this.scaleMap[JSON.parse(productCard.attr('data-product')).variations.unitOfMeasure]);
                                productCard.find('.add-quantity').find('.price').html('€ 0,00');
                                this._utils.updateAllCardsPicker(dataProduct.code, productCard.find('.add-quantity').find('.quantity').html(), false, productCard.find('.add-quantity').find('.price').html());
                            }else{
                                let currentPrice = 0;
                                let totalQty = 0;
                                let entries = response.data.cart.entries;
                                for(let entry in entries){
                                    if(entries[entry].productCode == JSON.parse(productCard.attr('data-product')).code){
                                        currentPrice += entries[entry].totalPrice.value;
                                        totalQty += entries[entry].quantity * 1000 * entries[entry].scale;
                                    }
                                }
                                totalQty = totalQty / 1000;
                                if(dataProduct.variations && dataProduct.variations.unitOfMeasure == "KG"){
                                    productCard.find('.add-quantity').find('.quantity').html((totalQty < 1 ? this._utils.parseScaleKgToGr(totalQty * 1000).toString().replace('.',',') : parseFloat(totalQty.toFixed(4))).toString()+ ' ' + (totalQty < 1 ? this.scaleMap["GR"] : this.scaleMap["KG"]));
                                }else if(dataProduct.variations && dataProduct.variations.unitOfMeasure == "GR"){
                                    productCard.find('.add-quantity').find('.quantity').html((totalQty >= 1000 ? (totalQty/1000).toString().replace('.',',') : totalQty).toString()+ ' ' + (totalQty >= 1000 ? this.scaleMap["KG"] : this.scaleMap["GR"]));
                                }else{
                                    productCard.find('.add-quantity').find('.quantity').html((totalQty >= 1000 ? (totalQty/1000).toString().replace('.',',') : totalQty).toString()+ ' ' + (totalQty >= 1000 ? this.scaleMap["KG"] : this.scaleMap["GR"]));
                                }
                                
                                productCard.find('.add-quantity').find('.price').html('€ ' + currentPrice.toFixed(2).toString().replace('.', ','));
                                this._utils.updateAllCardsPicker(dataProduct.code, productCard.find('.add-quantity').find('.quantity').html(), true, productCard.find('.add-quantity').find('.price').html(), true, false, 0);
                            }
                            productCard.attr('data-quantity', JSON.stringify(dataQuantity));
                            /* this._utils.updateEntryNumbers(_$(e).closest('body'), JSON.stringify(response.data.cart.entries)); */
                        
                            prezzo = 0;
                        }else{
                            let dataQuantity;
                            let dataEntryNumber;
                            if(productCard.attr('data-quantity')){
                                dataQuantity = JSON.parse(productCard.attr('data-quantity'));
                                dataEntryNumber = JSON.parse(productCard.attr('data-entryNumber'));
                            }else{
                                dataQuantity = {};
                                dataEntryNumber = {};
                            }
                            let currentPrice = 0;
                            let totalQty = 0;
                            let entries = response.data.cart.entries;
                            dataEntryNumber[size] = response.data.modifications.cartModifications[0].entry.entryNumber;
                            dataQuantity[size] = response.data.modifications.cartModifications[0].entry.quantity;
                            for(let entry in entries){
                                if(entries[entry].productCode == JSON.parse(productCard.attr('data-product')).code){
                                    currentPrice += entries[entry].totalPrice.value;
                                    totalQty += entries[entry].quantity * 1000 * entries[entry].scale;
                                    
                                }
                            }
                            totalQty = totalQty / 1000;
                            productCard.attr('data-quantity', JSON.stringify(dataQuantity));
                            productCard.attr('data-entryNumber', JSON.stringify(dataEntryNumber));

                            if(dataProduct.variations && dataProduct.variations.unitOfMeasure == "KG"){
                                productCard.find('.quantity').html((totalQty < 1 ? (this._utils.parseScaleKgToGr(totalQty * 1000)).toString().replace('.',',') : parseFloat(totalQty.toFixed(4))).toString()+ ' ' + (totalQty < 1 ? this.scaleMap["GR"] : this.scaleMap["KG"]));
                            }else if(dataProduct.variations && dataProduct.variations.unitOfMeasure == "GR"){
                                productCard.find('.quantity').html((totalQty >= 1000 ? (totalQty/1000).toString().replace('.',',') : totalQty).toString()+ ' ' + (totalQty >= 1000 ? this.scaleMap["KG"] : this.scaleMap["GR"]));
                            }else{
                                productCard.find('.quantity').html((totalQty >= 1000 ? (totalQty/1000).toString().replace('.',',') : totalQty).toString()+ ' ' + (totalQty >= 1000 ? this.scaleMap["KG"] : this.scaleMap["GR"]));
                            }

                            productCard.find('.price').html('€ ' + currentPrice.toFixed(2).toString().replace('.',','));
                            setTimeout(() => {
                                this.showAddRemoveMenu(e);
                                if(JSON.parse(productCard.attr('data-product')).variations.unitWeight){
                                    let unitWeight;
                                    if(JSON.parse(productCard.attr('data-product')).variations.unitOfMeasure == "KG"){
                                        unitWeight = JSON.parse(productCard.attr('data-product')).variations.unitWeight / 1000;
                                    }else{
                                        unitWeight = JSON.parse(productCard.attr('data-product')).variations.unitWeight;
                                    }

                                    let pz = Math.floor(totalQty / unitWeight);
                                    this.showAlertAddToCart(productCard, `La quantità aggiunta corrisponde a ca. ${pz} pezzi`);
                                }
                            }, 50);
                            prezzo = response.data.modifications.cartModifications[0].entry.totalPrice.value / response.data.modifications.cartModifications[0].quantity;
                            this._utils.updateAllCardsPicker(dataProduct.code, productCard.find('.add-quantity').find('.quantity').html(), true, productCard.find('.add-quantity').find('.price').html(), true, false, dataQuantity[dataProduct.variations.minWeight]);
                        }

                        if(type == 'add') {
                            this.sendAnalytics('aggiungiProdotto',e,size,prezzo);
                        } else if(type == 'remove') {
                            this.sendAnalytics('rimuoviProdotto',e,size,prezzo);
                        }
                        break;
    
                    case 'incremental':
                        this.toggleFeedbackOverlay(e);

                        if(response.data.modifications.cartModifications[0].quantity < 1){
                            this.hideAddRemoveMenu(productCard);
                            productCard.attr('data-scale', 0);
                            productCard.removeAttr('data-entryNumber');
                            this.hideAlert(productCard.find('.alert-add-to-cart'));
                            this._utils.updateEntryNumbers(_$('body'), JSON.stringify(response.data.cart.entries));
                            this._utils.updateRemovedProductOnPage(productType, dataProduct.code);
                            if(type == 'add') {
                                this.sendAnalytics('aggiungiProdotto',e,undefined,0);
                            } else if(type == 'remove') {
                                this.sendAnalytics('rimuoviProdotto',e,undefined,0);
                            }
                            this._utils.updateAllCardsPicker(dataProduct.code, productCard.find('.add-quantity').find('.quantity').html(), false, productCard.find('.add-quantity').find('.price').html());
                        }else{
                            let scale = response.data.modifications.cartModifications[0].entry.scale;
                            let incrementoMinimo = dataProduct.increment.minWeight;
                            let incrementoUnitario =dataProduct.increment.increment;
                            let totalPrice = response.data.modifications.cartModifications[0].entry.totalPrice.value;
                            if ((scale - incrementoMinimo) > 0) {
                                prezzo = totalPrice / (scale / incrementoUnitario);
                            } else {
                                prezzo = totalPrice;
                            }
                            productCard.attr('data-scale', response.data.modifications.cartModifications[0].entry.scale);
                            productCard.attr('data-entryNumber', response.data.modifications.cartModifications[0].entry.entryNumber);
                            productCard.attr('data-price',response.data.modifications.cartModifications[0].entry.totalPrice.value);
                            let qty = response.data.modifications.cartModifications[0].entry.scale;
                            if(dataProduct.increment && dataProduct.increment.unitOfMeasure == "KG"){
                                productCard.find('.add-quantity .quantity').html(qty < 1 ? (this._utils.parseScaleKgToGr(qty * 1000)).toString().replace('.',',') + ' g' : qty + ' kg');
                            }else if(dataProduct.increment && dataProduct.increment.unitOfMeasure == "GR"){
                                productCard.find('.add-quantity .quantity').html(qty >= 1000 ? (qty/1000).toString().replace('.',',') + ' kg' : qty + ' g');  
                            }else{
                                productCard.find('.add-quantity .quantity').html(qty >= 1000 ? (qty/1000).toString().replace('.',',') + ' kg' : qty + ' g');
                            }
                           
                            productCard.find('.add-quantity .price').html(response.data.modifications.cartModifications[0].entry.totalPrice.value.toFixed(2).toString().replace('.', ',')+'€');
                            setTimeout(() => {
                                this.showAddRemoveMenu(e);
                                if(JSON.parse(productCard.attr('data-product')).increment.unitWeight){
                                    let unitWeight;
                                    if(JSON.parse(productCard.attr('data-product')).increment.unitOfMeasure == "KG"){
                                        unitWeight = JSON.parse(productCard.attr('data-product')).increment.unitWeight / 1000;
                                    }else{
                                        unitWeight = JSON.parse(productCard.attr('data-product')).increment.unitWeight;
                                    }
                                    
                                    let pz = Math.floor(qty / unitWeight);
                                    this.showAlertAddToCart(productCard, `La quantità aggiunta corrisponde a ca. ${pz} pezzi`);
                                }
                            }, 50);
                            if(type == 'add') {
                                this.sendAnalytics('aggiungiProdotto',e,qty,prezzo);
                            } else if(type == 'remove') {
                                this.sendAnalytics('rimuoviProdotto',e,qty,prezzo);
                            }
                            this._utils.updateAllCardsPicker(dataProduct.code, productCard.find('.add-quantity').find('.quantity').html(), true, productCard.find('.add-quantity').find('.price').html());
                        }
                        break;
    
                    case 'heavy':
                        this.toggleFeedbackOverlay(e);
                        if(response.data.modifications.cartModifications[0].quantity < 1){
                            this.hideAlert(productCard.find('.alert-add-to-cart'));
                            this.hideAddRemoveMenu(productCard);
                            this._utils.updateEntryNumbers(_$(e).closest('body'), JSON.stringify(response.data.cart.entries));
                            productCard.find('.add-quantity .quantity').html('0');
                            productCard.attr('data-entryNumber', '');
                            this._utils.updateRemovedProductOnPage(productType, dataProduct.code);
                            this._utils.updateAllCardsPicker(dataProduct.code, productCard.find('.add-quantity').find('.quantity').html(), false, productCard.find('.add-quantity').find('.price').html());
                        }else{
                            productCard.find('.add-quantity .quantity').html(response.data.modifications.cartModifications[0].quantity.toString());
                            productCard.attr('data-entryNumber',response.data.modifications.cartModifications[0].entry.entryNumber);
                            productCard.find('.add-quantity .price').html(response.data.modifications.cartModifications[0].entry.totalPrice.value.toFixed(2).toString().replace('.', ',')+'€');
                            this._utils.updateAllCardsPicker(dataProduct.code, productCard.find('.add-quantity').find('.quantity').html(), true, productCard.find('.add-quantity').find('.price').html());
                        }
                        if(type == 'add'){
                            Window.volume.cartVolume += dataProduct.volume;
                            this.showAddRemoveMenu(e);
                        }else{
                            Window.volume.cartVolume -= dataProduct.volume;
                        }
                        productCard.attr('data-quantity', response.data.modifications.cartModifications[0].quantity);
                        this._utils.updateCartBtn(response.data.cart.subTotal.value, response.data.cart.totalUnitCount);
    
                        this.checkHeavyItem(productCard, type == 'add' ? true : false);
                        promoAlertNeeded = false;
                        contingency = false;
                        activePromo = {};
                        /* Controlla se ci sono promo per gli alert */
                        if(dataProduct.promo){
                            let promoArray = dataProduct.promo;
                            promoArray.forEach((index, element) => {
                                if((promoArray[element].itOrderSap || promoArray[element].itOrderEurecart) && !contingency){
                                    contingency = true;
                                    promoAlertNeeded = true;
                                    activePromo = promoArray[element];
                                }else if(['PRODUCT_PARTNER_FIXED_DISCOUNT',
                                    'PRODUCT_PARTNER_MIX_FIXED_DISCOUNT',
                                    'PRODUCT_N_FIXED_DISCOUNT',
                                    'PRODUCT_ADDITIONAL_PERCENTAGE_DISCOUNT'].indexOf(promoArray[element].promoType) != -1 && !promoAlertNeeded){
                                    promoAlertNeeded = true;
                                    activePromo = promoArray[element];
                                }
                            })
                        }
                        if(promoAlertNeeded){
                            if(response.data.modifications.cartModifications[0].quantity == 0){
                                this.hideAlertWarning(productCard);
                            }else{ 
                                if(contingency){
                                    this.manageContingency(productCard, activePromo, response.data.modifications.cartModifications[0].quantity,
                                        response.data.modifications.cartModifications[0].entry.basePrice ? response.data.modifications.cartModifications[0].entry.basePrice.value : 0);
                                }else{
                                    let promoQuantity = activePromo.promoType == 'PRODUCT_PARTNER_MIX_FIXED_DISCOUNT' ? 
                                        this._utils.checkNxMProducts(response.data.cart, activePromo.partnerProducts, dataProduct.code)
                                        : response.data.modifications.cartModifications[0].quantity;
                                    this.showPromoAlert(productCard, activePromo, promoQuantity,
                                        response.data.modifications.cartModifications[0].entry.basePrice ? response.data.modifications.cartModifications[0].entry.basePrice.value : 0);
                                }
                            }
                        }
                        if(response.data.modifications.cartModifications[0].quantity){
                            prezzo = response.data.modifications.cartModifications[0].entry.totalPrice.value / response.data.modifications.cartModifications[0].quantity;
                        }else{
                            prezzo = 0;
                        }
                        
                        if(type == 'add') {
                            this.sendAnalytics('aggiungiProdotto',e,undefined,prezzo);
                        } else if(type == 'remove') {
                            this.sendAnalytics('rimuoviProdotto',e,undefined,prezzo);
                        }
                        break;
                    case 'base':
                        this.toggleFeedbackOverlay(e);
                        if(response.data.modifications.cartModifications[0].quantity < 1){
                            this.hideAlert(productCard.find('.alert-add-to-cart'));
                            this.hideAddRemoveMenu(productCard);
                            this._utils.updateEntryNumbers(_$(e).closest('body'), JSON.stringify(response.data.cart.entries));
                            productCard.find('.add-quantity .quantity').html('0');
                            productCard.attr('data-entryNumber', '');
                            this._utils.updateRemovedProductOnPage(productType, dataProduct.code);
                            this._utils.updateAllCardsPicker(dataProduct.code, productCard.find('.add-quantity').find('.quantity').html(), false, productCard.find('.add-quantity').find('.price').html());
                        }else{
                            productCard.find('.add-quantity .quantity').html(response.data.modifications.cartModifications[0].quantity.toString());
                            productCard.attr('data-entryNumber',response.data.modifications.cartModifications[0].entry.entryNumber);
                            productCard.find('.add-quantity .price').html(response.data.modifications.cartModifications[0].entry.totalPrice.value.toFixed(2).toString().replace('.', ',')+'€');
                            this._utils.updateAllCardsPicker(dataProduct.code, productCard.find('.add-quantity').find('.quantity').html(), true, productCard.find('.add-quantity').find('.price').html());
                        }
                        if(type == 'add'){
                            this.showAddRemoveMenu(e);
                            /* if(response.data.modifications.cartModifications[0].quantity == 1){
                                this.lsfTrack(e,'cart');
                            } */
                        }
                        promoAlertNeeded = false;
                        contingency = false;
                        activePromo = {};
                        /* Controlla se ci sono promo per gli alert */
                        
                        if(dataProduct.promo){
                            let promoArray = dataProduct.promo;
                            promoArray.forEach((index, element) => {
                                if((promoArray[element].itOrderSap || promoArray[element].itOrderEurecart) && !contingency){
                                    contingency = true;
                                    promoAlertNeeded = true;
                                    activePromo = promoArray[element];
                                }else if(['PRODUCT_PARTNER_FIXED_DISCOUNT',
                                    'PRODUCT_PARTNER_MIX_FIXED_DISCOUNT',
                                    'PRODUCT_N_FIXED_DISCOUNT',
                                    'PRODUCT_ADDITIONAL_PERCENTAGE_DISCOUNT'].indexOf(promoArray[element].promoType) != -1 && !promoAlertNeeded){
                                    promoAlertNeeded = true;
                                    activePromo = promoArray[element];
                                }
                            })
                        }
                        if(promoAlertNeeded){
                            if(response.data.modifications.cartModifications[0].quantity == 0){
                                this.hideAlertWarning(productCard);
                            }else{ 
                                if(contingency){
                                    this.manageContingency(productCard, activePromo, response.data.modifications.cartModifications[0].quantity,
                                        response.data.modifications.cartModifications[0].entry.basePrice ? response.data.modifications.cartModifications[0].entry.basePrice.value : 0);
                                }else{
                                    let promoQuantity = activePromo.promoType == 'PRODUCT_PARTNER_MIX_FIXED_DISCOUNT' ? 
                                        this._utils.checkNxMProducts(response.data.cart, activePromo.partnerProducts, dataProduct.code)
                                        : response.data.modifications.cartModifications[0].quantity;
                                    this.showPromoAlert(productCard, activePromo, promoQuantity,
                                        response.data.modifications.cartModifications[0].entry.basePrice ? response.data.modifications.cartModifications[0].entry.basePrice.value : 0);
                                }
                            }
                        }
                        productCard.attr('data-quantity', response.data.modifications.cartModifications[0].quantity);
                        this._utils.updateCartBtn(response.data.cart.subTotal.value, response.data.cart.totalUnitCount); 
                        if(response.data.modifications.cartModifications[0].quantity){
                            prezzo = response.data.modifications.cartModifications[0].entry.totalPrice.value / response.data.modifications.cartModifications[0].quantity;
                        }else{
                            prezzo = 0;
                        }
                        
                        if(type == 'add') {
                            this.sendAnalytics('aggiungiProdotto',e,undefined,prezzo);
                        } else if(type == 'remove') {
                            this.sendAnalytics('rimuoviProdotto',e,undefined,prezzo);
                        }
                }
    
                if(type == 'add' && response.data.modifications.cartModifications[0].quantity == 1 &&
                response.data.modifications.cartModifications[0].quantityAdded == 1){
                    this.lsfTrack(e,'cart');
                }
                this._utils.updateCartBtn(response.data.cart.subTotal.value, response.data.cart.totalUnitCount);
                this._utils.updateProductsOnPage(response.data.cart.entries);
                if(_$('body').hasClass('page-DetailCart')){
                    this._detailCart.reloadCart();
                }
            }else if(statusCode == "lowStock" || statusCode == "noStock"){
                this._utils.manageLowStock(dataProduct.code, true);
            }
            
            
        }

        let error = (err) => {
            if(productType == 'variable') {
                this._utils.showLoader(_$(e), false, LOADER_POSITION.BUTTON_ADDCART_FROM_TAG);
            } else {
                this._utils.showLoader(_$(e), false, LOADER_POSITION.BUTTON);
            }

            if(err.response.data.code == 'BACKEND:OUT_OF_STOCK_ERROR') {
                _$(productCard).find('.add-quantity').addClass('uk-hidden');
                _$(productCard).find('.add-product').removeClass('uk-hidden').prop('disabled', true);
                _$(productCard).find('.alert-out-of-stock').removeClass('uk-hidden');
            }else if(this._lockerUtils.parseLockerErrorMessageAddToCart(err.response.data.message) != null){
                let jsonError = this._lockerUtils.parseLockerErrorMessageAddToCart(err.response.data.message);
                if(jsonError['maxPiecesLk'] != null){
                    this._lockerUtils.manageLockerContingencyModal(jsonError['maxPiecesLk'], true, false);
                    if(jsonError['maxPiecesLk'] == 0){
                        _$(productCard).find('.add-product').prop('disabled', true);
                    }else if(jsonError['maxPiecesLk'] > 0){
                        _$(productCard).find('.add-quantity .add-quantity-button:last-child').prop('disabled', true);
                    }
                }else if(jsonError['maxKgLk'] != null){
                    this._lockerUtils.manageLockerContingencyModal(jsonError['maxKgLk'], false, true);
                    if(productType != 'variable'){
                        if(jsonError['maxKgLk'] == 0){
                            _$(productCard).find('.add-product').prop('disabled', true);
                        }else if(jsonError['maxKgLk'] > 0){
                            _$(productCard).find('.add-quantity .add-quantity-button:last-child').prop('disabled', true);
                        }
                    }
                }
            }
        }
        let body;

        
        
        switch(productType){
            case 'variable':
                if(productCard.attr('data-quantity') && JSON.parse(productCard.attr('data-quantity'))[size]){
                    let dataQuantity = JSON.parse(productCard.attr('data-quantity'))[size];
                    let entryNumber = JSON.parse(productCard.attr('data-entryNumber'))[size];

                    body = {
                        "productId": dataProduct.code,
                        "qty": type=="remove" ? dataQuantity - 1 : dataQuantity + 1,
                        "scale": size,
                        "entryNumber": entryNumber
                    }
                    
                }else{
                    body = {
                        "productId": dataProduct.code,
                        "qty": 1,
                        "scale": size
                    }
                    
                }

                body["weight"] = body["scale"]
                break;

            case 'incremental':
                
                if(productCard.attr('data-scale') && parseFloat(productCard.attr('data-scale')) > 0){
                    if(type == "add"){
                        body = {
                            "productId": dataProduct.code,
                            "qty": 1,
                            "scale": parseFloat(productCard.attr('data-scale')) + dataProduct.increment.increment,
                            "entryNumber": productCard.attr('data-entryNumber')
                        }
                    }else if(parseFloat(productCard.attr('data-scale')) - dataProduct.increment.increment < dataProduct.increment.minWeight){
                        body = {
                            "productId": dataProduct.code,
                            "qty": 0,
                            "entryNumber": productCard.attr('data-entryNumber'),
                            "scale" : dataProduct.increment.minWeight
                        }
                    }else{
                        body = {
                            "productId": dataProduct.code,
                            "qty": 1,
                            "scale" : parseFloat(productCard.attr('data-scale')) - dataProduct.increment.increment,
                            "entryNumber": productCard.attr('data-entryNumber')
                        }
                    }
                }else{
                    body = {
                        "productId": dataProduct.code,
                        "qty": 1,
                        "scale": dataProduct.increment.minWeight
                    }
                }

                body["weight"] = body["scale"];

                break;
                
            case 'heavy':
                if(productCard.attr('data-quantity') == '0' || productCard.attr('data-quantity')==undefined){
                    body = {
                        "productId": dataProduct.code, 
                        "qty": 1
                    }
                }
                else{
                    let newQty;
                    if(type == 'remove'){
                        newQty = parseInt(productCard.attr('data-quantity')) - 1;
                    }else{
                        newQty = parseInt(productCard.attr('data-quantity')) + 1;
                    }
                    body = {
                        "productId": dataProduct.code, 
                        "qty": newQty,
                        "entryNumber": productCard.attr('data-entryNumber')
                    }
                }

                if(dataProduct.netQuantityUm && dataProduct.netQuantityUm.toUpperCase() != "PZ"){
                    body["weight"] = dataProduct.netQuantity ? dataProduct.netQuantity : 0;
                }

                break;
            case 'base':
                if(productCard.attr('data-quantity') == '0' || productCard.attr('data-quantity')==undefined){
                    body = {
                        "productId": dataProduct.code, 
                        "qty": 1
                    }
                }
                else{
                    let newQty;
                    if(type == 'remove'){
                        newQty = parseInt(productCard.attr('data-quantity')) - 1;
                    }else{
                        newQty = parseInt(productCard.attr('data-quantity')) + 1;
                    }
                    body = {
                        "productId": dataProduct.code, 
                        "qty": newQty,
                        "entryNumber": productCard.attr('data-entryNumber')
                    }
                }

                if(dataProduct.netQuantityUm && dataProduct.netQuantityUm.toUpperCase() != "PZ"){
                    body["weight"] = dataProduct.netQuantity ? dataProduct.netQuantity : 0;
                }

                break;
        }

        console.log(body);
        this.cartService.addRemoveItemToCart(success, error, body);
    }

    manageContingency(card: object, promo: object, currentQuantity?: number, basePrice?: number){
        if(promo['itOrderSap']){
            this.hideAlertWarning(card);
            this.showAlertContingency(card, promo['itOrderSap']);
            _$(card).find('.add-quantity .total .price').prepend(`<div class="price-old">€ ${(currentQuantity * basePrice).toFixed(2).toString().replace('.', ',')}</div>`);
        }else if(promo['itOrderEurecart']){
            this.hideAlertWarning(card);
            this.showAlertContingency(card, promo['itOrderEurecart']);
        }
    }

    showPromoAlert(card: object, promo: object, currentQuantity?: number, basePrice?: number){
        let promoType = promo['promoType'];
        let quantityThreshold = promo['quantityThreshold'] ? promo['quantityThreshold']: '';
        let buyX, getY, missingForPromo;
        switch(promoType){
            case 'PRODUCT_PARTNER_MIX_FIXED_DISCOUNT':
                buyX = promo['buyX'];
                getY = promo['getY'];
                missingForPromo = (buyX + getY) - currentQuantity%(buyX + getY);
                if(currentQuantity == 0){
                    this.hideAlert(_$(card).find('.alert-add-to-cart'));
                }else if(currentQuantity > 0 && missingForPromo == (buyX + getY)){
                    this.showAlertAddToCart(card, `Hai applicato la promo ${buyX+getY}x${buyX} Mix che ti fa ottenere gratis uno di questi prodotti`);
                }else if(missingForPromo > 1){
                    this.showAlertAddToCart(card, `Aggiungi ` +missingForPromo+` prodotti ${buyX+getY}x${buyX} Mix per applicare la promozione`);
                }else if(missingForPromo == 1){
                    this.showAlertAddToCart(card, `Aggiungi un altro prodotto ${buyX+getY}x${buyX} Mix per applicare la promozione`);
                }
                break;
            case 'PRODUCT_PARTNER_FIXED_DISCOUNT':
                buyX = promo['buyX'];
                getY = promo['getY'];
                missingForPromo = (buyX + getY) - currentQuantity%(buyX + getY);
                if(currentQuantity == 0){
                    this.hideAlert(_$(card).find('.alert-add-to-cart'));
                }else if(currentQuantity > 0 && missingForPromo == (buyX + getY)){
                    this.showAlertAddToCart(card, `L'offerta è stata applicata al tuo carrello!`);
                    _$(card).find('.add-quantity .total .price').prepend(`<div class="price-old">€ ${(basePrice * currentQuantity).toFixed(2).toString().replace('.', ',')}</div>`);
                }else if(missingForPromo > 1){
                    this.showAlertAddToCart(card, `Aggiungi altri ` +missingForPromo+` pezzi per applicare l'offerta!`);
                }else if(missingForPromo == 1){
                    this.showAlertAddToCart(card, `Aggiungi un altro pezzo per applicare l'offerta!`);
                }
                break;
            case 'PRODUCT_N_FIXED_DISCOUNT':
                if(currentQuantity == 0){
                    this.hideAlert(_$(card).find('.alert-add-to-cart'));
                }else if(currentQuantity > 0 && currentQuantity >= quantityThreshold){
                    this.showAlertAddToCart(card, `L'offerta è stata applicata al tuo carrello!`)
                    _$(card).find('.add-quantity .total .price').prepend(`<div class="price-old">€ ${(basePrice * currentQuantity).toFixed(2).toString().replace('.', ',')}</div>`);
                }else if(quantityThreshold - currentQuantity%quantityThreshold > 1){
                    this.showAlertAddToCart(card, `Aggiungi altri ${quantityThreshold - currentQuantity%quantityThreshold} pezzi per applicare l'offerta!`);
                }else if(quantityThreshold - currentQuantity%quantityThreshold == 1){
                    this.showAlertAddToCart(card, `Aggiungi un altro pezzo per applicare l'offerta!`);
                }
                break;
            case 'PRODUCT_ADDITIONAL_PERCENTAGE_DISCOUNT':
                if(currentQuantity == 0){
                    this.hideAlert(_$(card).find('.alert-add-to-cart'));
                }else if(currentQuantity >= quantityThreshold){
                    this.showAlertAddToCart(card, `L'offerta è stata applicata al tuo carrello!`)
                    _$(card).find('.add-quantity .total .price').prepend(`<div class="price-old">€ ${(basePrice * currentQuantity).toFixed(2).toString().replace('.', ',')}</div>`);
                }else if(quantityThreshold - currentQuantity > 1){
                    this.showAlertAddToCart(card, `Aggiungi altri ${quantityThreshold - currentQuantity%quantityThreshold} pezzi per applicare l'offerta!`);
                }else if(quantityThreshold - currentQuantity == 1){
                    this.showAlertAddToCart(card, `Aggiungi un altro pezzo per applicare l'offerta!`);
                }
                break;
        }
    }

    showAddRemoveMenu(e: object){
        _$(e).closest('.component-ProductCard').find('.add-product').addClass("uk-hidden");
        _$(e).closest('.component-ProductCard').find('.add-quantity').removeClass("uk-hidden");
    }

    hideAddRemoveMenu(e: object){
        _$(e).closest('.component-ProductCard').find('.add-product').removeClass("uk-hidden");
        _$(e).closest('.component-ProductCard').find('.add-quantity').addClass("uk-hidden"); 
    }

    lsfTrack(e: object, action: string){
        let productCode = JSON.parse(_$(e).closest('.component-ProductCard').attr('data-product')).code;
        let searchQuery = JSON.parse(_$(e).closest('.component-ProductCard').attr('data-product')).query;
        this.updateSearchQuery(_$(e).closest('.component-ProductCard'));
        this._utils.callLsfTrack(action, productCode, searchQuery);
        if (action == 'view') {
            this.sendAnalytics('dettaglioProdotto',_$(e));
        }
    }

    hideAlert(e: object){
        _$(e).closest('.uk-alert').addClass('uk-hidden');
    }

    showAlertContingency(e:object, text: string){
        _$(e).find('.alert-contingency-warning p span.pezzi-contingency').html(text);
        _$(e).find('.alert-contingency-warning').removeClass('uk-hidden');
    }

    hideAlertWarning(e: object){
        _$(e).find('.alert-heavy-warning').addClass('uk-hidden');
        _$(e).find('.alert-contingency-warning').addClass('uk-hidden');
        _$(e).find('.alert-heavy-block-oncart').addClass('uk-hidden');
        _$(e).find('.alert-heavy-block-offcart').addClass('uk-hidden');
    }

    showHeavyWarning(e:object, alertType: string){
        switch(alertType){
            case 'surcharge':
                window.clearTimeout(this.heavySurchargeTimer);
                _$(e).find('.alert-heavy-warning').removeClass('uk-hidden');
                this.heavySurchargeTimer = setTimeout(() => {
                    _$(e).find('.alert-heavy-warning').addClass('uk-hidden');
                }, 7000);
                break;
            case 'heavy-limit-oncart':
                _$(e).find('.alert-heavy-block-oncart').removeClass('uk-hidden');
                break;
            case 'heavy-limit-offcart':
                _$(e).find('.alert-heavy-block-offcart').removeClass('uk-hidden');
                break;
            default:
                console.log('no alert type heavy');
                break;
        }
    }

    /* Controllo per mostrare gli alert dei prodotti pesanti */
    checkHeavyItem(e: object, added = false){
        let dataProduct = JSON.parse(_$(e).attr('data-product'));
        
        if(dataProduct.volume + Window.volume.cartVolume > Window.volume.maxVolume){
            this.hideAlertWarning(_$(e));
            this.showHeavyWarning(_$(e), 'heavy-limit-oncart');
            _$(e).find('.add-quantity .add-quantity-button:last-child').prop('disabled', true);
        }else if(Window.volume.cartVolume >= Window.volume.maxVolumeNoSurcharge &&
            Window.volume.cartVolume - dataProduct.volume < Window.volume.maxVolumeNoSurcharge && added){
            this.hideAlertWarning(_$(e));
            this.showHeavyWarning(_$(e), 'surcharge');
            _$(e).find('.add-quantity .add-quantity-button:last-child').prop('disabled', false);
        }else{
            this.hideAlertWarning(e);
            _$(e).find('.add-quantity .add-quantity-button:last-child').prop('disabled', false);
        }
    }

    showAlertAddToCart(card: object, text: string){
        _$(card).find('.alert-add-to-cart p span').html(text);
        _$(card).find('.alert-add-to-cart').removeClass('uk-hidden');
    }

    selectPezzaturaSostitutivo(e: object){
        _$(e).closest('.weight-list-sostitutivi').find('.weight-list-sostitutivi-chip a').removeClass('active');
        _$(e).addClass('active');
    }

    async sendAnalytics(eventType: string, e: object, variante?, prezzo?){
        try {
            let analyticsData;
            let funnelDict;
            let myEvent = eventType;

            let page = "";
            if (_$('body').attr('nkpage')) {
                page = _$('body').attr('nkpage').toString();
            } else {
                page = "ProdottiPreferiti";
            }
            
            if (page == 'DetailCart') {
                switch (myEvent){
                    case 'aggiungiProdotto':
                        myEvent = 'aggiungiDaCarosello';
                        break;
                    case 'rimuoviProdotto':
                        myEvent = 'rimuoviDaCarosello';
                        break;
                    case 'prodottoPreferito':
                        myEvent = 'preferitoDaCarosello';
                        break;
                    case 'dettaglioProdotto':
                        myEvent = 'dettaglioDaCarosello';
                        break;
                }
            }
    
            analyticsData = {
                event: myEvent,
                prodotto: (await this._analytics.getProductData(_$(e).closest('.component-ProductCard'), false, myEvent, variante, prezzo)),
            }
    
            analyticsData.prodotto['carosello'] = _$(e).closest('.section-carousel').find('.section-title').html();
            
            switch (myEvent) {
                case 'aggiungiDaCarosello':
                    //4.2.15
                    funnelDict = 'FUNNEL_DATA_CART_PRODUCT_CARD_ADD_TO_CART';
                    analyticsData['IDpromozioni'] = this._analytics.getIDpromozioni();
                    break;
                case 'rimuoviDaCarosello':
                    //4.2.16
                    funnelDict = 'FUNNEL_DATA_CART_PRODUCT_CARD_REMOVE_FROM_CART';
                    analyticsData['IDpromozioni'] = this._analytics.getIDpromozioni();
                    break;
                case 'preferitoDaCarosello':
                    //4.2.17
                    funnelDict = _$(e).hasClass('active') ? 'FUNNEL_DATA_CART_PRODUCT_CARD_ADD_FAVOURITE' : 'FUNNEL_DATA_CART_PRODUCT_CARD_REMOVE_FAVOURITE';
                    analyticsData['IDpromozioni'] = this._analytics.getIDpromozioni();
                    break;
                case 'dettaglioDaCarosello':
                    //4.2.18
                    funnelDict = 'FUNNEL_DATA_CART_PRODUCT_CARD_CLICK_PRODUCT';
                    analyticsData['IDpromozioni'] = this._analytics.getIDpromozioni();
                    break;
                case 'aggiungiProdotto':
                    if (page == 'DetailProduct') {
                        funnelDict = 'FUNNEL_DATA_DET_PROD_ADD_TO_CART';
                    } else if (page == 'Homepage') {
                        funnelDict = 'FUNNEL_DATA_HOME_ADD_TO_CART';
                    } else if (page == 'ProdottiPreferiti') {
                        funnelDict = 'FUNNEL_DATA_PROD_PREF_ADD_TO_CART';
                    } else {
                        funnelDict = window.location.pathname == window['urlSite'].search ? 'FUNNEL_DATA_PRODUCT_CARD_ADD_TO_CART_SEARCH' : 'FUNNEL_DATA_PRODUCT_CARD_ADD_TO_CART_CHOOSE';
                    }
                    break;
                case 'rimuoviProdotto':
                    if (page == 'DetailProduct') {
                        funnelDict = 'FUNNEL_DATA_DET_PROD_REMOVE_FROM_CART';
                    } else if (page == 'Homepage') {
                        funnelDict = 'FUNNEL_DATA_HOME_REMOVE_FROM_CART';
                    } else if (page == 'ProdottiPreferiti') {
                        funnelDict = 'FUNNEL_DATA_PROD_PREF_ADD_TO_CART';
                    } else {
                        funnelDict = window.location.pathname == window['urlSite'].search ? 'FUNNEL_DATA_PRODUCT_CARD_REMOVE_FROM_CART_SEARCH' : 'FUNNEL_DATA_PRODUCT_CARD_REMOVE_FROM_CART_CHOOSE';
                    }
                    break;
                case 'prodottoPreferito':
                    if (page == 'DetailProduct') {
                        funnelDict = _$(e).hasClass('active') ? 'FUNNEL_DATA_DET_PROD_ADD_FAVOURITE' : 'FUNNEL_DATA_DET_PROD_REMOVE_FAVOURITE';
                    } else if (page == 'Homepage') {
                        funnelDict = _$(e).hasClass('active') ? 'FUNNEL_DATA_HOME_ADD_FAVOUR' : 'FUNNEL_DATA_HOME_REMOVE_FAVOUR';
                    } else if (page == 'ProdottiPreferiti') {
                        funnelDict = 'FUNNEL_DATA_PROD_PREF_ADD_TO_CART';
                    } else {
                        if (window.location.pathname == window['urlSite'].search) {
                            funnelDict = _$(e).hasClass('active') ? 'FUNNEL_DATA_PRODUCT_CARD_ADD_FAVOURITE_SEARCH' : 'FUNNEL_DATA_PRODUCT_CARD_REMOVE_FAVOURITE_SEARCH';
                        } else {
                            funnelDict = _$(e).hasClass('active') ? 'FUNNEL_DATA_PRODUCT_CARD_ADD_FAVOURITE_CHOOSE' : 'FUNNEL_DATA_PRODUCT_CARD_REMOVE_FAVOURITE_CHOOSE';
                        }
                    }
                    break;
                case 'dettaglioProdotto':
                    if (page == 'DetailProduct') {
                        funnelDict = 'FUNNEL_DATA_DET_PROD_CLICK_PRODUCT';
                    } else if (page == 'Homepage') {
                        funnelDict = 'FUNNEL_DATA_HOME_CLICK_PRODUCT';
                    } else if (page == 'ProdottiPreferiti') {
                        funnelDict = 'FUNNEL_DATA_PROD_PREF_ADD_TO_CART';
                    } else if (page == 'PersonalAreaPage') {
                        funnelDict = 'FUNNEL_PERSONAL_AREA_CLICK_PRODUCT';
                    } else {
                        funnelDict = window.location.pathname == window['urlSite'].search ? 'FUNNEL_DATA_PRODUCT_CARD_CLICK_PRODUCT_SEARCH' : 'FUNNEL_DATA_PRODUCT_CARD_CLICK_PRODUCT_CHOOSE';
                    }
                    break;
            }
    
            this._analytics.sendAnalytics(analyticsData, funnelDict);
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }

    parseJson(){
        let message = "Response not successful, 500 returned from esb --- {\"errorCode\":\"HTTP500\",\"errorMessage\":\"null - Locker limit exceeded\",\"maxKgLk\":\"2.0\",\"systemInfo\":{\"targetSystem\":\"SAP\",\"endpoint\":\"https://api.cfp5zmx7oc-conadscrl1-d1-public.model-t.cc.commerce.ondemand.com/occ/v2/conad/users/anonymous/carts/22362f7f-14a5-4549-a172-7aa5516030ae/entries/0?language=it\"}}";
        let messageError = message.split('---')[1].trim();
        console.log(JSON.parse(messageError));
    
    }
}
