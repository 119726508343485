import { _$, _Uikit, Window, _zoom } from '../../main';
import { LocalStorage } from '../local-storage/local-storage';
import * as mobile_detect from 'mobile-device-detect';
import { Analytics } from '../analytics/analytics';
import { UserService } from '../user-service/user-service';
import { LsfService } from '../lsf-service/lsf-service';
import { AddressUser } from '../user-service/user-service.interface';
import PerfectScrollbar from 'perfect-scrollbar';
import { StoreService } from "../store-service/store-service";
import { GoogleAddressUtils } from './google-address-utils';

export enum LOADER_POSITION {
    GEOLOCALIZATION_INPUT,
    MINICART_LOAD,
    OFFCANVAS_BODY,
    BUTTON,
    USER_MENU,
    ADD_TO_CART,
    CARD_DELIVERY,
    SUBSTITUTIONS,
    CHECKOUT_BOX_STEP,
    OFFCANVAS_BOX_OVERLOW,
    BUTTON_ADDCART_FROM_TAG,
    USER_DASHBOARD,
    FULLPAGE_ORANGE
}

export class Utils {

    public myInterval;
    public labelTypeOfServiceGenericModal = '';
    public labelTypeOfServiceGenericModal2 = '';
    public labelPDVAddress = '';
    public labelPDVName = '';
    /* Traccia l'ultimo prodotto aggiunto nella pdp per gestire le note di preparazione */
    public pdpLastModifiedItem = {};
    public modalSlotExtensionField = Window.selectedTimeslot && Window.selectedTimeslot.extension ? Window.selectedTimeslot.extension : 0;
    private scaleMap = {
        "MG": "mg",
        "CG": "cg",
        "GR": "g",
        "HG": "hg",
        "KG": "kg"
    }
    /* handler dei timeout di scadenza timeslot */
    private timeWarningFirstHandler;
    private timeWarningLastHandler;
    private timeSlotExpiredHandler;
    public autofocusEnabled = true;
    public lastFieldAutofocus;
    private _currentDeliveryFormattedAddress = Window.selectedAddress && Window.selectedAddress.completeAddress && Window.selectedAddress.completeAddress.formattedAddress ? Window.selectedAddress.completeAddress.formattedAddress : '';
    
    /* whitelist classi cliccabili nelle pagine google */
    private googlePageWhitelistClasses = ['component-Breadcrumb', 
                                            'swiper-product-detail-image', 
                                            'btn-zoom', 
                                            'swiper-product-detail-image-modal', 
                                            'product-other-info', 
                                            'manage-preferite-product',
                                            'next-suggested',
                                            'prev-suggested',
                                            'scrollbar-suggested',
                                            'next-listing-swiper-offers',
                                            'prev-listing-swiper-offers',
                                            'scrollbar-listing-swiper-offers',
                                            'link-approfondisci',
                                            'text-link',
                                            'component-Pagination',
                                            'scrollUp'];

    public scrollbarList = {}
    public draggedByScrollbar = false;
    constructor(
        private _analytics: Analytics,
        private _localStorage: LocalStorage,
        public userService: UserService,
        public _lsfService: LsfService,
        public storeService: StoreService,
        private googleAddressUtils: GoogleAddressUtils
    ) {
        this.getDetectBrowserMobile();
        this.initCustomSelect2();
        this.showModalWhenNotLogged();

        this.checkTimerSlotPrenotatoScadenza();

        this.moveScrollDownToMain();

        this.trackingOnShowGenericModal();
        this.labelTypeOfServiceGenericModal = Window.typeOfService === 'HOME_DELIVERY' ? 'consegna' : 'ritiro';
        this.labelTypeOfServiceGenericModal2 = Window.typeOfService === 'HOME_DELIVERY' ? 'la consegna' : 'il ritiro';
        this.labelPDVName = Window.pointOfService ? Window.pointOfService?.name : '';
        this.labelPDVAddress = Window.pointOfService ? Window.pointOfService?.address?.formattedAddress : '';

        if (_$("body").attr("nkPage") !== 'Entrypage' && _$("body").attr("nkPage") !== 'DetailOrder') {
            this.managePopupServiceUnavailable();
        }

        if (_$("body").hasClass("page-CheckoutNew")) {
            this.customAutofocusHandler();
        }

        _Uikit.util.on("#modalBlackList1", "show", () => {
            this.sendAnalyticsBlacklistModal('pageview');
        });

        _Uikit.util.on("#modalBlackList2", "show", () => {
            this.sendAnalyticsBlacklistModal('pageview');
        });

        _$('#modalBlackList1').find('.uk-button').on('click', () => { this.sendAnalyticsBlacklistModal('clickContattaciBannerBlacklist'); })
        _$('#modalBlackList2').find('.uk-text-center').on('click', () => { this.sendAnalyticsBlacklistModal('clickContattaciBannerBlacklist'); })

        if (Window.user && this.isInBlackList() && !_$('body').hasClass('page-CheckoutNew')) {

            let alreadySet = localStorage.getItem('blacklist');

            if (!alreadySet) {
                _Uikit.util.on("#modalBlackList1", "hide", () => {
                    _$("#paymentBlackListed").removeClass("uk-hidden");
                });

                localStorage.setItem('blacklist', 'true');
                _Uikit.modal('#modalBlackList1').show();
            }
            else {
                _$("#paymentBlackListed").removeClass("uk-hidden");
            }
        }

        _$(() => {
            this.checkCartStatus();
            this.customScrollbar();
            this.managePreferiteAriaLabel();
            this.addRoleChatbox();
            var els = null;
            els = document.querySelectorAll("[data-append-username]");
            els.forEach((el) => {
                var name = Window.user ? Window.user.nome : "";
                el.innerHTML = el.innerHTML.replace("{0}", name);
            });

            els = document.querySelectorAll("[data-check-islogged]");
            els.forEach((el) => {
                if (Window.user) el.style.display = "none";
            });
        })
    }

    nkOnInit() {
    }

    /**
     * Appendere al main scrollup / down
     */
    moveScrollDownToMain() {
        let main = _$('main.main-content');
        _$('#scrollDownEntry, .scrollUp').appendTo(main);
    }


    /**
     * Shows loader
     * @param el = elemento di input
     * @param [show] = true/false show loader
     * @param loader =
     * @returns
     */
    showLoader(el: any = '', show: boolean = true, loader?: LOADER_POSITION) {
        try {
            switch (loader) {
                case LOADER_POSITION.GEOLOCALIZATION_INPUT:
                    if (show === true) {
                        _$(el).parent().append('<div class="lds-ring lds-ring-1" uk-spinner="ratio: 0.7"></div>');
                    } else {
                        if(_$('.lds-ring').length) {
                            _$('.lds-ring').remove();
                        }
                    }
                    break;
                case LOADER_POSITION.MINICART_LOAD:
                    if (show === true) {
                        _$(el).html('<div class="lds-ring lds-ring-2 orange" uk-spinner="ratio: 1"></div>');
                    } else {
                        if(_$('.lds-ring').length) {
                            _$('.lds-ring').remove();
                        }
                    }
                    break;
                case LOADER_POSITION.OFFCANVAS_BODY:
                    if (show === true) {
                        _$(el).parent().append('<div class="lds-ring lds-ring-1" uk-spinner="ratio: 0.7"></div>');
                    } else {
                        if(_$('.lds-ring').length) {
                            _$('.lds-ring').remove();
                        }
                    }
                    break;
                case LOADER_POSITION.BUTTON:
                    if (show === true) {
                        _$(el).prop('disabled', true);
                        _$(el).append('<div class="lds-ring lds-ring-1" uk-spinner="ratio: 0.7"></div>');
                    } else {
                        _$(el).prop('disabled', false);
                        if(_$('.lds-ring').length) {
                            _$('.lds-ring').remove();
                        }
                    }
                    break;
                case LOADER_POSITION.BUTTON_ADDCART_FROM_TAG:
                    if (show === true) {
                        _$(el).closest('.component-ProductCard').find('.add-product').prop('disabled', true);
                        _$(el).closest('.component-ProductCard').find('.add-product').append('<div class="lds-ring lds-ring-1" uk-spinner="ratio: 0.7"></div>');
                    } else {
                        _$(el).closest('.component-ProductCard').find('.add-product').prop('disabled', false);
                        if(_$('.lds-ring').length) {
                            _$('.lds-ring').remove();
                        }
                    }
                    break;
                case LOADER_POSITION.USER_MENU:
                    if (show === true) {
                        _$(el).append('<div class="lds-ring lds-ring-2" uk-spinner="ratio: 0.7"></div>');
                    } else {
                        if(_$('.lds-ring').length) {
                            _$('.lds-ring').remove();
                        }
                    }
                    break;
                case LOADER_POSITION.ADD_TO_CART:
                    if (show === true) {
                        _$(el).prop('disabled', true);
                        _$(el).append('<div class="lds-ring" uk-spinner="ratio: 0.7"></div>');
                    } else {
                        _$(el).prop('disabled', false);
                        if(_$('.lds-ring').length) {
                            _$('.lds-ring').remove();
                        }
                    }
                    break;
                case LOADER_POSITION.CARD_DELIVERY:
                    if (show === true) {
                        _$(el).find('.giorno-orario-edit').addClass('uk-hidden');
                        _$(el).append('<div class="lds-ring lds-ring-3 orange" uk-spinner="ratio: 0.7"></div>');
                    } else {
                        if(_$('.lds-ring').length) {
                            _$('.lds-ring').remove();
                        }
                        _$(el).find('.giorno-orario-edit').removeClass('uk-hidden');
                    }
                    break;
                case LOADER_POSITION.SUBSTITUTIONS:
                case LOADER_POSITION.USER_DASHBOARD:
                    if(show === true){
                        _$(el).append('<div class="lds-ring lds-ring-4 orange" uk-spinner="ratio: 0.7"></div>');
                    } else {
                        if(_$('.lds-ring').length) {
                            _$('.lds-ring').remove();
                        }
                    }
                    break;
                case LOADER_POSITION.CHECKOUT_BOX_STEP:
                    if(show === true){
                        _$(el).append('<div class="lds-ring lds-ring-3 orange" uk-spinner="ratio: 0.7"></div>');
                    } else {
                        if(_$('.lds-ring').length) {
                            _$('.lds-ring').remove();
                        }
                    }
                    break;
                case LOADER_POSITION.OFFCANVAS_BOX_OVERLOW:
                    if(show === true){
                        _$(el).append('<div class="lds-ring" uk-spinner="ratio: 0.7"></div>');
                    } else {
                        if(_$('.lds-ring').length) {
                            _$('.lds-ring').remove();
                        }
                    }
                    break;
                case LOADER_POSITION.FULLPAGE_ORANGE:
                    if (show === true) {
                        _$(el).append('<div class="lds-ring lds-ring-fullpage orange"><div></div></div>');
                    } else {
                        if(_$('.lds-ring').length) {
                            _$('.lds-ring').remove();
                        }
                    }
                    break;
                default:
                    if(show === true){
                        _$('html').append('<div class="lds-ring lds-ring-fullpage orange"><div></div></div>');
                    } else {
                        if(_$('.lds-ring').length) {
                            _$('.lds-ring').remove();
                        }
                    }
                    return false;
            }

        } catch (loader) {
            console.log('error loading: ', loader);
        }
    }

    /**
     * Show/Hide per CTA show password input
     * @param el
     */
    showHidePwd(el: object) {
        let input = _$(el).parent().find('input')[0];
        _$(el).children().removeClass('icon-eye-on icon-eye-off');
        if (input.type === 'password') {
            input.type = 'text';
            _$(el).children().addClass('icon-eye-on');
        } else {
            input.type = 'password';
            _$(el).children().addClass('icon-eye-off');
        }
    }

    /**
     * Show/Hide per CTA pulisci value input
     * @param el
     */
    checkValueInput(el: object) {
        _$(el).removeClass('uk-form-danger');
        _$(el).parent().find('.message-error').addClass('uk-hidden');
        _$(el).parent('.uk-label-float').removeClass('form-control-error');
        let removeButton = _$(el).parent().find('.remove');
        if (removeButton.length) {
            if (_$(el).val() && _$(el).val() !== '') {
                removeButton.show();
            } else {
                removeButton.hide();
            }
        }
    }

    //funzione al momento non utilizzata, capire se riutilizzare o cancellare
    detectValueInput(e, origin: LOADER_POSITION) {
        if (origin === 0) {
            let button = _$('.location-finder').parent().find('.submitButton');
            _$(button).attr('disabled', 'true');
            if (_$(e).val() !== '') {
                _$(button).attr('disabled', 'false');
            }
        }
    }

    /**
     * visualizza gli errori negli input
     * @param el
     */
    checkMessageError(el: object) {
        let messageError = _$(el).parent().find('.message-error');
        if(_$(el).prop('required') && _$(el).attr('data-valid') === 'false') {
            if (_$(el).is('select')) {
                messageError = _$(el).parents('.select-native-ui').find('.message-error');
            }
            if(_$(el).val() === '' || _$(el).attr('data-valid') === 'false') {
                _$(el).addClass('uk-form-danger');
                if (messageError) {
                    _$(messageError).removeClass('uk-hidden');
                }
            // } else if (_$(el).val() && _$(el).val() !== '') {
            //     if (messageError) {
            //         _$(messageError).addClass('uk-hidden');
            //     }
            } else {
                _$(el).removeClass('uk-form-danger');
                if (messageError) {
                    _$(messageError).addClass('uk-hidden');
                }
            }
        }

        if(!_$(el).prop('required') && _$(el).attr('data-valid') === 'false' && _$(el).val() === '') {
            _$(messageError).addClass('uk-hidden');
        }
    }

    /**
     * rimuovi il valore dall'input
     * @param el
     */
    removeValueInput(el: object) {
        let input = _$(el).parent().find('input');
        input.val('');
        _$(el).hide();
        _$(input).removeClass('uk-form-danger');
        _$(input).parent('.uk-label-float').removeClass('form-control-error');

        if(_$(el).closest('.uk-label-float').find('#orderRicevi2').length){
            _$(el).closest('.uk-label-float').find('#orderRicevi2').attr('data-valid', 'false');
        }

        _$(input).parent().find('.message-error').addClass('uk-hidden');
    }

    /**
     * rileva il browser mobile in uso
     */
    getDetectBrowserMobile() {
        
        if (mobile_detect.isIOS) {
            //iOS
            _$('html').addClass('browser-type__ios');
            _$('html').addClass('os-version__'+ mobile_detect.fullBrowserVersion);

            if (mobile_detect.isSafari) {
                //iOS Safari
                _$('html').addClass('browser_safari');
            } else if (mobile_detect.isChrome) {
                //iOS Chrome
                _$('html').addClass('browser_chrome');
            }
        } else if (mobile_detect.isAndroid) {
            //Android

            _$('html').addClass('browser-type__android');
            if (mobile_detect.isChrome) {
                //Android Chrome
                _$('html').addClass('browser_chrome');
            }
        }

        if (mobile_detect.isMobile) {
            _$('html').addClass('device_mobile');
        }

        if (mobile_detect.isTablet) {
            _$('html').addClass('device_tablet');
        }

        if(!mobile_detect.isMobile && !mobile_detect.isTablet){
            _$('html').addClass('device_not_mobile_not_tablet');
        }
    }

    /**
     * Calcolo altezza dei singoli componenti per generare l'overflow del box centrale
     * @param e
     */
    calculateHeightforOffcanvas(e: string, type?: any) {
        let window = Window.innerHeight ? Window.innerHeight : _$(Window).height();
        let header;
        let topbar = _$(e).find('.progress-steps').outerHeight();
        if (type == 'switcher') {
            header = topbar + _$(e).find('.uk-offcanvas-wrapper.uk-active').children('.uk-offcanvas-header').outerHeight();
            //header = topbar + _$(e).find('.step-1').children('.uk-offcanvas-header').outerHeight();
            //} else if(step === 1) {
            //  header = topbar + _$(e).find('.step-2').children('.uk-offcanvas-header').outerHeight();
        }
        _$(e).find('.uk-offcanvas-body').css('height', window - header);

        _$(Window).on('resize orientationchange', function () {
            let window = _$(Window).innerHeight();
            let header;
            let topbar = _$(e).find('.progress-steps').outerHeight();
            if (type == 'switcher') {
                header = topbar + _$(e).find('.uk-offcanvas-wrapper.uk-active').children('.uk-offcanvas-header').outerHeight();
            }
            _$(e).find('.uk-offcanvas-body').css('height', window - header);
        });
    }

    /**
     * funzione generica che chiude gli offcanvas
     * @param el
     */
    closeOffcanvas(el: string) {
        _Uikit.offcanvas(el).hide();
    }

    /**
     * Gestisce il box rileva posizione per gli input in pagina
     * @param allInputs
     */
    showBoxOnPermit(allInputs) {
        _$(allInputs).on('click input', function (e) {
            const inputSpecificBox = _$(`#${e.target.id} ~ .pac-container-custom`);
            if (e.target.value != '') {
                inputSpecificBox.hide();
            } else {
                inputSpecificBox.show();
            }
            /*navigator.permissions.query({name: 'geolocation'})
                .then(status => {
                    if (status.state === "prompt" || status.state === "granted") {
                        this.value.length > 0 ? inputSpecificBox.hide() : inputSpecificBox.show();
                    } else {
                        inputSpecificBox.hide();
                    }
                })*/
        });
    }

    /**
     * Chiusura del box rileva posizione
     * @param detectPositionBox
     */
    hideBox(detectPositionBox) {
        _$(document).mouseup(function (e) {
            if (!detectPositionBox.is(e.target) && detectPositionBox.has(e.target).length === 0) {
                detectPositionBox.hide();
            }
        });
    }


    /**
     * inizializzo il Form per la Validazione
     * @param formID
     */
    initFormValidator(formID) {
        let textFields = _$('#'+formID).find('input, select, textarea');
        for(let i = 0; i < textFields.length; i++) {
            if(_$(textFields[i]).val() && _$(textFields[i]).val() === '') {
                _$(textFields[i]).attr('data-valid', 'true');
            } else {
                _$(textFields[i]).attr('data-valid', 'false');
            }
        }
    }


    /**
     * Controllo tutti i campi del <form> se sono required e con data-valid = true
     * @param submitButtonID
     * @param formID
     */
    checkFormValidator(submitButtonID: string, formID: string) {
        
        let textFields = _$('#'+formID).find('input[required], select[required], textarea[required]');
        let isDisabled = false;
        for (let i = 0; i < textFields.length; i++) {
            if(!_$(textFields[i]).prop('required') || _$(textFields[i]).attr('data-valid') !== 'true') {
                isDisabled = true;
            }
        }
        _$('#'+submitButtonID).prop('disabled', isDisabled);
        if(isDisabled) {
            _$('#'+submitButtonID).attr('title', 'Compila i campi per proseguire');
        } else {
            _$('#'+submitButtonID).removeAttr('title');
        }

        if(_$('body').hasClass('page-CheckoutNew')){
            _$('#'+submitButtonID).prop('disabled', false);
        }
    }

    /**
     * Checks input value on keyup
     * @param element
     * @param formId
     */
    controlValueFieldInput(element,  formId?: string, submitButtonID?: string) {
        _$('#'+ formId).find(element).removeClass('uk-form-danger');
        let pattern = _$('#'+ formId).find(element).attr("pattern");
        if(_$('#'+ formId).find(element).val()) {
            //@ts-ignore
            if (_$('#'+ formId).find(element).val().match(pattern)) {
                _$('#'+ formId).find(element).attr('data-valid', 'true');
                _$('#'+ formId).find(element).removeClass('uk-form-danger');
                _$('#'+ formId).find(element).parent().find('.message-error').addClass('uk-hidden');
            } else {
                _$('#'+ formId).find(element).attr('data-valid', 'false');
                _$('#'+ formId).find(element).addClass('uk-form-danger');
                _$('#'+ formId).find(element).parent().find('.message-error').removeClass('uk-hidden');
            }
        } else {
            _$('#'+ formId).find(element).attr('data-valid', 'false');
            _$('#'+ formId).find(element).parent().find('.message-error').addClass('uk-hidden');
        }
        this.checkFormValidator(submitButtonID, formId);
    }

    /**
     * Determines whether valid email is
     * @param email
     * @returns true if valid email
     */
    isValidEmail(email: string): boolean {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+[a-z0-9-]+\.[a-zA-Z]{2,4}$/;
        return regex.test(String(email).toLowerCase().trim());
    }

    isValidGuestNumber(number: number, pattern: string): boolean{
        if(number && pattern){
            let numberString = number.toString();
            return numberString.replace(/\s/g, '').match(pattern) ? true : false
        }else{
            return false
        }
    }

    /**
     * Determines whether only letters has
     * @param value
     * @returns true if has only letters
     */
    hasOnlyLetters(value: string): boolean {
        const regex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/;
        return regex.test(String(value));
    }

    /**
     * Hides or show form errors
     * @param type
     */
    hideShowFormError(type: string, value: string, pattern?: string): void {
        /*if((type === 'email' && !this.isValidEmail(value)) || (type !== 'email' && !this.hasOnlyLetters(value))) this._showError(type);
        else if((type === 'email' && this.isValidEmail(value)) || (type !== 'email' && this.hasOnlyLetters(value))) this._hideError(type);*/
        switch (type) {
            case 'email':
            case 'emailGuest':
            case 'emailOrder':
                this.isValidEmail(value) ? this._hideError(type) : this._showError(type);
                break;
            case 'phone':
                value.replace(/\s/g, '').match(pattern) ? this._hideError(type) : this._showError(type);
                break;
            case 'codePhone':
                //case 'codePromo' :
                value.length > 0 ? this._hideError(type) : this._showError(type);
                break;
            case 'cconad_number':
            case 'cconad_cod':
                value.length > 0 ? this._hideError(type) : this._showError(type);
                break;
            case 'otpCheck':
                value.length > 0 ? this._showOTPError('codePhone') : '';
                break;
            default:
                this.hasOnlyLetters(value) ? this._hideError(type) : this._showError(type);
                break;
        }
    }

    /**
     * Shows error
     * @param type
     */
    _showError(type: string) {
        const formInput = _$('[nkInput="' + type + '"]');
        const errorLabel = _$('[nkError="' + type + '"]');
        const form = _$('[nkForm="' + type + '"]');
        if (!formInput.hasClass('uk-form-danger') && errorLabel.hasClass('uk-hidden')) {
            formInput[0].classList.add('uk-form-danger');
            form[0].classList.add('form-control-error');
            errorLabel[0].classList.remove('uk-hidden');
        }
    }

    /**
     * Shows OTP error
     * @param type
     */
     _showOTPError(type: string) {
        const formInput = _$('[nkInput="' + type + '"]');
        const form = _$('[nkForm="' + type + '"]');
        if (!formInput.hasClass('uk-form-danger')) {
            formInput[0].classList.add('uk-form-danger');
            form[0].classList.add('form-control-error');
        }
    }

    /**
     * Hides error
     * @param type
     */
    _hideError(type: string) {
        const formInput = _$('[nkInput="' + type + '"]');
        const errorLabel = _$('[nkError="' + type + '"]');
        const form = _$('[nkForm="' + type + '"]');
        if (formInput.hasClass('uk-form-danger')) {
            formInput[0].classList.remove('uk-form-danger');
            form[0].classList.remove('form-control-error');
            errorLabel[0].classList.add('uk-hidden');
        }
    }

    isMobile() {
        let widthWindow = Window.innerWidth > 0 ? Window.innerWidth : screen.width;
        return widthWindow <= 767;
    }

    isTablet() {
        let widthWindow = Window.innerWidth > 0 ? Window.innerWidth : screen.width;
        return widthWindow >= 768 && widthWindow <= 1023;
    }

    customScrollbar(disableVerticalScroll = false) {
        //@ts-ignore
        // _$('.box-overflow').mCustomScrollbar({
        //     theme: 'dark',
        //     mouseWheel: {
        //         enable: true,
        //         scrollAmount: 'auto',
        //     },
        //     scrollInertia: 2000,
        //     contentTouchScroll: 400,
        //     documentTouchScroll: false,
        //     advanced: {
        //         autoScrollOnFocus: false,
        //         updateOnImageLoad: false,
        //     },
        //     scrollEasing: 'easeInOut',
        //     callbacks: {
        //         /* Le seguenti callback funzionano all'interno dell'offcanvas search filters */
        //         /* Aggiungono la classe per il box-shadowing allo scroll */
        //         onScrollStart: function () {
        //             if (_$(this).closest('#offcanvasSearchFilter').length) {
        //                 _$(this).siblings('#search-bar-modal').addClass('scroll');
        //                 _$(this)
        //                     .closest('.list-filters')
        //                     .find('.filter-div:not(:has(.input-filter))')
        //                     .find('.filter-title')
        //                     .addClass('scroll');
        //             }
        //             if (_$(this).closest('.uk-offcanvas').length) {
        //                 _$(this).parent('.uk-offcanvas-body').siblings('.uk-offcanvas-header').addClass('scroll');
        //             }
        //         },
        //         onTotalScrollBack: function () {
        //             if (_$(this).closest('#offcanvasSearchFilter').length) {
        //                 _$(this).siblings('#search-bar-modal').removeClass('scroll');
        //                 _$(this).closest('.list-filters').find('.filter-title.scroll').removeClass('scroll');
        //             }
        //             if (_$(this).closest('.uk-offcanvas').length) {
        //                 _$(this).parent('.uk-offcanvas-body').siblings('.uk-offcanvas-header').removeClass('scroll');
        //             }
        //         },
        //         onOverflowYNone: function () {
        //             if (_$(this).closest('#offcanvasSearchFilter').length) {
        //                 _$(this).siblings('#search-bar-modal').removeClass('scroll');
        //                 _$(this).closest('.filters-container').find('.filter-title.scroll').removeClass('scroll');
        //             }
        //             if (_$(this).closest('.uk-offcanvas').length) {
        //                 _$(this).parent('.uk-offcanvas-body').siblings('.uk-offcanvas-header').removeClass('scroll');
        //             }
        //         },
        //     },
        // });

        //@ts-ignore
        _$('.js-scroll').each((index, value) => {

            if(_$(value).hasClass('ps') || (_$(window).width() < 1024 && _$(value).hasClass('section-timeslot'))){
                return;
            }

            let options = {
                handlers: ['click-rail', 'wheel', 'touch', 'drag-thumb', 'keyboard'],
                minScrollbarLength: 30,
                maxScrollbarLength: 180,
                wheelSpeed: 0.5,
                wheelPropagation: false,
                useBothWheelAxes: true,
                suppressScrollY: disableVerticalScroll && _$(value).hasClass('section-timeslot')
            }

            let ps = new PerfectScrollbar(_$(value)[0], options);

            if(_$(value).prop('id') != null && _$(value).prop('id') != ''){
                this.scrollbarList[_$(value).prop('id')] = ps;
            }

            /* I seguenti eventListerner funzionano all'interno dell'offcanvas search filters */
            /* Aggiunge la classe per il box-shadowing allo scroll */
            _$(value).on('ps-scroll-y', () => {
                if (_$(this).parent().closest('#offcanvasSearchFilter').length) {
                    _$(this).parent().siblings('#search-bar-modal').addClass('scroll');
                    _$(this).parent()
                        .closest('.list-filters')
                        .find('.filter-div:not(:has(.input-filter))')
                        .find('.filter-title')
                        .addClass('scroll');
                }
                if (_$(this).parent().closest('.uk-offcanvas').length) {
                    _$(this).parent().parent('.uk-offcanvas-body').siblings('.uk-offcanvas-header').addClass('scroll');
                }
            });

            /* Rimuove la classe per il box-shadowing allo scroll */
            _$(value).on('ps-y-reach-start', () => {
                if (_$(this).parent().closest('#offcanvasSearchFilter').length) {
                    _$(this).parent().siblings('#search-bar-modal').removeClass('scroll');
                    _$(this).parent().closest('.list-filters').find('.filter-title.scroll').removeClass('scroll');
                }
                if (_$(this).parent().closest('.uk-offcanvas').length) {
                    _$(this).parent().parent('.uk-offcanvas-body').siblings('.uk-offcanvas-header').removeClass('scroll');
                }
            })

            /* Resize scrollbar */
            _$(Window).on('resize orientationchange', function () {
                ps.update();
            });
        });


        if(!_$('body').hasClass('page-DetailCart')){
            //@ts-ignore
            _$('.box-overflow-arrow').mCustomScrollbar({
                theme: 'dark',
                mouseWheel: {
                    enable: true,
                    scrollAmount: 50,
                    deltaFactor: 50,
                    preventDefault: true
                },
                scrollButtons: { enable: true },
                keyboard: {enable: true},
                scrollInertia: 0,
                contentTouchScroll: 200,
                documentTouchScroll: false,
                advanced: {
                    autoScrollOnFocus: false,
                    updateOnImageLoad: false,
                },
                scrollEasing: 'linear'
            });
        }
    }

    callUpdateScrollbar(id){
        if(_$('#'+id).length && this.scrollbarList[id] != null){
            this.scrollbarList[id].update();
        }
    }

    /**
     * Checkbox custom graphic
     * @param e
     */
    checkBox(e: object) {
        if(_$(e).is('#checkout-ascensore') || _$(e).is('#checkout-portineria') || _$(e).is('#checkout-sales-conditions')){

            if(_$(e).find('input:checked').length){
                _$(e).find('input').siblings('.checkmark').removeClass('selected');
                _$(e).find('input.uk-radio').removeClass('selected');
                _$(e).find('input').prop('checked', false);
            }else{
                _$(e).find('input').siblings('.checkmark').addClass('selected');
                _$(e).find('input.uk-radio').addClass('selected');
                _$(e).find('input').prop('checked', true);
            }

            if(_$(e).prop('id') == 'checkout-sales-conditions'){
                if(_$(e).find('input').prop('checked')){
                    _$(e).attr('aria-pressed', 'true');
                    this._localStorage.setItem('checkCGV', 'true');
                }else{
                    _$(e).attr('aria-pressed', 'false');
                    this._localStorage.setItem('checkCGV', 'false');
                }
            }

            if(_$('#checkout-sales-conditions').find('input').is(':checked')){
                _$('#checkout-sales-conditions').find('.checkmark').removeClass('error-required-checkout');
                _$('#checkout-sales-conditions-message-error').addClass('uk-hidden');
            }
            
        }else{
            _$(e).find('input:checked').siblings('.checkmark').addClass('selected');
            _$(e).find('input:checked.uk-radio').addClass('selected');

            _$(e).find('input:not(:checked)').siblings('.checkmark').removeClass('selected');
            _$(e).find('input:not(:checked).uk-radio').removeClass('selected');
            
            if(_$(e).prop('id') == 'checkout-sales-conditions'){
                if(_$(e).find('input').prop('checked')){
                    _$(e).attr('aria-pressed', 'true');
                    this._localStorage.setItem('checkCGV', 'true');
                }else{
                    _$(e).attr('aria-pressed', 'false');
                    this._localStorage.setItem('checkCGV', 'false');
                }
            }
        }
        
    }

    /**
     * controllo checkbox data-value
     */
    detectCheckboxValue(e, formId, submitButtonID) {
        if (_$(e).find('input').is(':checked')) {
            _$(e).find('input').attr('data-valid', 'true');
        } else {
            _$(e).find('input').attr('data-valid', 'false');
        }

        this.checkFormValidator(submitButtonID, formId);
    }

    /**
     * Convert format bytes (Byte, Kb, Mb, etc...)
     * @param bytes
     * @param decimals
     */
    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }


    /* Trigger custom per il cambiamento del quantity dal dettaglio carrello */
    detectQuantityChange(){
        _$('.primary-panel').on('click', '.component-ProductCardDetailCart .quantity .select-items', function(){
            _$(this).closest('.quantity').find('.custom-select').trigger('change');
        });
    }

    /**
     * Inizializzo la New Select Custom
     */
    initCustomSelect2() {
        let _this = this;
        _$('.js-select-custom select').each(function () {
            let selectOnlyTextFormatDesktop = _$(this).closest('.js-select-custom').hasClass('js-select-custom-cart-txt-desktop-scale');
            let selectOnlyTextQtyMobile = _$(this).closest('.js-select-custom').hasClass('js-select-custom-cart-txt-mobile-qty');
            // Cache the number of options
            let $this = _$(this);
            let numberOfOptions = _$(this).children('option').length;

            // Hides the select element
            $this.addClass('s-hidden');

            // Wrap the select element in a div
            if(!$this.parent('.select-custom').length) {
                $this.wrap('<div class="select-custom"></div>');
            }

            // Insert a styled div to sit over the top of the hidden select element
            if(!$this.siblings('.styledSelect').length) {
                $this.after('<div role="link" class="styledSelect uk-input uk-form-large uk-text-truncate"></div>');
            }

            // Cache the styled div
            let $styledSelect = $this.next('div.styledSelect');

            // Show the value select option in the styled div
            $styledSelect.parents('.js-select-custom').removeClass('empty');
            if($this.val()) {
                if(selectOnlyTextFormatDesktop){
                    $styledSelect.text('Formato da ' + $this.children('option').filter(':selected').text());
                }else if(selectOnlyTextQtyMobile){
                    $styledSelect.text('Qtà ' + $this.children('option').filter(':selected').text());
                }else{
                    $styledSelect.text($this.children('option').filter(':selected').text());
                }
            } else {
                $styledSelect.parents('.js-select-custom').addClass('empty');
            }


            //reset -- Insert an unordered list after the styled div and also cache the list
            let $list = _$('<ul />').html('');
            // Insert an unordered list after the styled div and also cache the list
            $list = _$('<ul />', {
                'class': 'options box-overflow-arrow'
            }).insertAfter($styledSelect);

            // Insert a list item into the unordered list for each select option
            for (let i = 0; i < numberOfOptions; i++) {
                let classSelected = $this.val() === $this.children('option').eq(i).val() ? 'selection' : '';
                _$('<li />', {
                    text: $this.children('option').eq(i).text(),
                    rel: $this.children('option').eq(i).val(),
                    class: classSelected
                }).appendTo($list);
            }

            // Cache the list items
            let $listItems = $list.children('li');

            //$this.val(_$(this).attr('rel'));

            // Show the unordered list when the styled div is clicked (also hides it if the div is clicked again)
            $styledSelect.click(function (e) {
                e.stopPropagation();
                _$('div.styledSelect.active').each(function () {
                    _$(this).removeClass('active').next('ul.options').hide();
                });
                _$(this).toggleClass('active').next('ul.options').toggle();

                //Scorrere le lista fino al selected item
                if(_$(this).next('ul.options').find('li').hasClass('selection')) {
                    const selectedItem = _$(this).next('ul.options').find('.selection');
                    _$(selectedItem).parent().css('top', selectedItem.position().top + 'px');
                }

            });

            _$('.mCSB_buttonUp').on('click', () => {
                _$(this).parents('ul.options').css('display', 'block');
            });

            // Hides the unordered list when a list item is clicked and updates the styled div to show the selected list item
            // Updates the select element to have the value of the equivalent option

            $listItems.click(function (e) {
                _$($listItems).removeClass('selection');
                e.stopPropagation();
                if(selectOnlyTextFormatDesktop){
                    $styledSelect.text('Formato da ' + _$(this).text());
                }else if(selectOnlyTextQtyMobile){
                    $styledSelect.text('Qtà ' + _$(this).text());
                }else{
                    $styledSelect.text(_$(this).text()).removeClass('active')
                }
                
                $this.val(_$(this).attr('rel'));
                _$(this).addClass('selection');
                $list.hide();

                if($this.val()) {
                    $styledSelect.parents('.js-select-custom').removeClass('empty');
                } else {
                    $styledSelect.parents('.js-select-custom').addClass('empty');
                }

                // trigger select
                $this.trigger("change");

                _this.customSelectValue($this.attr('id'), $this.val());
            });

            // Hides the unordered list when clicking outside of it
            _$(document).click(function(e) {
                if (!(_$(e.target).attr('class') === 'mCSB_buttonUp' 
                    || _$(e.target).attr('class') === 'mCSB_buttonDown' 
                    || _$(e.target).attr('class') === 'mCSB_draggerContainer'
                    || _$(e.target).attr('class') === 'mCSB_dragger_bar'
                    || _$(e.target).attr('class') === 'mCustomScrollBox mCS-dark mCSB_vertical mCSB_inside'
                    || _$(e.target).attr('class') === 'mCSB_draggerRail')) {
                    $styledSelect.removeClass('active');
                    $list.hide();
                }   
            });
        });
    }

    initSingleCustomSelect(e: object){
        let _this = this;
        let customSelect = _$(e).closest('.js-select-custom');
        let options = _$(customSelect).find('.select-label').length ? '<div class="select-label">Qtà</div>' : '';
        let dataProduct = _$(e).closest('.component-ProductCardDetailCart').length && _$(e).closest('.component-ProductCardDetailCart').attr('data-product') ? JSON.parse(_$(e).closest('.component-ProductCardDetailCart').attr('data-product')) : '';
        let maxQty;
        if(dataProduct && dataProduct.increment){
            options = options.concat('<select onchange="ProductCardDetailCart.updateQuantity(this)">');
            maxQty = _$(e).closest('.component-ProductCardDetailCart').length && _$(e).closest('.component-ProductCardDetailCart').attr('data-maxQuantity') ? parseInt(_$(e).closest('.component-ProductCardDetailCart').attr('data-maxQuantity')) : 20;
            let maxOpts = maxQty;
            let currentQuantity = _$(customSelect).find('select option.selection').val() ? _$(customSelect).find('select option.selection').val() : 0;
            options = options.concat(`<option value="0">0 g</option>`);
            if(dataProduct.increment.minWeight == currentQuantity){
                if(dataProduct.increment.unitOfMeasure == "KG"){
                    options = options.concat(`<option value="${dataProduct.increment.minWeight}" class="selection" selected>${dataProduct.increment.minWeight < 1 ? dataProduct.increment.minWeight * 1000 +' g' : dataProduct.increment.minWeight +' kg'}</option>`);
                }else if(dataProduct.increment.unitOfMeasure == "GR"){
                    options = options.concat(`<option value="${dataProduct.increment.minWeight}" class="selection" selected>${dataProduct.increment.minWeight > 999 ? dataProduct.increment.minWeight/1000 +' kg' : dataProduct.increment.minWeight +' g'}</option>`);
                }else{
                    options = options.concat(`<option value="${dataProduct.increment.minWeight}" class="selection" selected>${dataProduct.increment.minWeight > 999 ? dataProduct.increment.minWeight/1000 +' kg' : dataProduct.increment.minWeight +' g'}</option>`);
                }
            }else{
                if(dataProduct.increment.unitOfMeasure == "KG"){
                    options = options.concat(`<option value="${dataProduct.increment.minWeight}">${dataProduct.increment.minWeight < 1 ? dataProduct.increment.minWeight * 1000 +' g': dataProduct.increment.minWeight + ' kg'}</option>`);
                }else if(dataProduct.increment.unitOfMeasure == "GR"){
                    options = options.concat(`<option value="${dataProduct.increment.minWeight}">${dataProduct.increment.minWeight > 999 ? dataProduct.increment.minWeight/1000 +' kg': dataProduct.increment.minWeight + ' g'}</option>`);
                }else{
                    options = options.concat(`<option value="${dataProduct.increment.minWeight}">${dataProduct.increment.minWeight > 999 ? dataProduct.increment.minWeight/1000 +' kg': dataProduct.increment.minWeight + ' g'}</option>`);
                }
            }
            for(let i = dataProduct.increment.minWeight + dataProduct.increment.increment, x = 0; x <= maxOpts; i+= dataProduct.increment.increment, x++){
                i = parseFloat(i.toFixed(4));
                if(i != currentQuantity){
                    if(dataProduct.increment.unitOfMeasure == "KG"){
                        options = options.concat(`<option value="${i}">${i < 1 ? i * 1000 + ' g' : i + ' kg'}</option>`);
                    }else if(dataProduct.increment.unitOfMeasure == "GR"){
                        options = options.concat(`<option value="${i}">${i > 999 ? i/1000 + ' kg' : i + ' g'}</option>`);
                    }else{
                        options = options.concat(`<option value="${i}">${i > 999 ? i/1000 + ' kg' : i + ' g'}</option>`);
                    }
                    
                }else{
                    if(dataProduct.increment.unitOfMeasure == "KG"){
                        options = options.concat(`<option value="${i}" class="selection" selected>${i < 1 ? i * 1000 +' g' : i+' kg'}</option>`);
                    }else if(dataProduct.increment.unitOfMeasure == "GR"){
                        options = options.concat(`<option value="${i}" class="selection" selected>${i > 999 ? i/1000 +' kg' : i+' g'}</option>`);
                    }else{
                        options = options.concat(`<option value="${i}" class="selection" selected>${i > 999 ? i/1000 +' kg' : i+' g'}</option>`);
                    }
                }
            }
        }else{
            maxQty = _$(e).closest('.component-ProductCardDetailCart').length && _$(e).closest('.component-ProductCardDetailCart').attr('data-maxQuantity') ? parseInt(_$(e).closest('.component-ProductCardDetailCart').attr('data-maxQuantity')) : 99;
            options = options.concat('<select onchange="ProductCardDetailCart.updateQuantity(this)">');
            let maxOpts = maxQty;
            let currentQuantity = _$(customSelect).find('select option.selection').text() ? parseInt(_$(customSelect).find('select option.selection').text()) : 0;
            for(let i = 0; i <= maxOpts; i++){
                if(i != currentQuantity){
                    options = options.concat(`<option value="${i}">${i}</option>`);
                }else{
                    options = options.concat(`<option value="${i}" class="selection" selected>${i}</option>`);
                }
            }
            options = options.concat('</select>');
        }
        
        _$(customSelect).html(options);
        _$(customSelect).find('select').each(function(){
            let selectOnlyTextFormatDesktop = _$(this).closest('.js-select-custom').hasClass('js-select-custom-cart-txt-desktop-scale');
            let selectOnlyTextQtyMobile = _$(this).closest('.js-select-custom').hasClass('js-select-custom-cart-txt-mobile-qty');
            // Cache the number of options
            let $this = _$(this);
            let numberOfOptions = _$(this).children('option').length;

            // Hides the select element
            $this.addClass('s-hidden');

            // Wrap the select element in a div
            if(!$this.parent('.select-custom').length) {
                $this.wrap('<div class="select-custom"></div>');
            }

            // Insert a styled div to sit over the top of the hidden select element
            if(!$this.siblings('.styledSelect').length) {
                $this.after('<div class="styledSelect uk-input uk-form-large uk-text-truncate"></div>');
            }

            // Cache the styled div
            let $styledSelect = $this.next('div.styledSelect');

            // Show the value select option in the styled div
            $styledSelect.parents('.js-select-custom').removeClass('empty');
            if($this.val()) {
                if(selectOnlyTextFormatDesktop){
                    $styledSelect.text('Formato da ' + $this.children('option').filter(':selected').text());
                }else if(selectOnlyTextQtyMobile){
                    $styledSelect.text('Qtà ' + $this.children('option').filter(':selected').text());
                }else{
                    $styledSelect.text($this.children('option').filter(':selected').text());
                }
            } else {
                $styledSelect.parents('.js-select-custom').addClass('empty');
            }


            //reset -- Insert an unordered list after the styled div and also cache the list
            let $list = _$('<ul />').html('');
            // Insert an unordered list after the styled div and also cache the list
            $list = _$('<ul />', {
                'class': 'options box-overflow-arrow'
            }).insertAfter($styledSelect);

            // Insert a list item into the unordered list for each select option
            for (let i = 0; i < numberOfOptions; i++) {
                let classSelected = $this.val() === $this.children('option').eq(i).val() ? 'selection' : '';
                _$('<li />', {
                    text: $this.children('option').eq(i).text(),
                    rel: $this.children('option').eq(i).val(),
                    class: classSelected
                }).appendTo($list);
            }

            // Cache the list items
            let $listItems = $list.children('li');

            //$this.val(_$(this).attr('rel'));

            // Show the unordered list when the styled div is clicked (also hides it if the div is clicked again)
            $styledSelect.click(function (e) {
                e.stopPropagation();
                _$('div.styledSelect.active').each(function () {
                    _$(this).removeClass('active').next('ul.options').hide();
                });
                _$(this).toggleClass('active').next('ul.options').toggle();

                //Scorrere le lista fino al selected item
                if(_$(this).next('ul.options').find('li').hasClass('selection')) {
                    const selectedItem = _$(this).next('ul.options').find('.selection');
                    _$(selectedItem).parent().css('top', selectedItem.position().top + 'px');
                }

            });

            _$('.mCSB_buttonUp').on('click', () => {
                _$(this).parents('ul.options').css('display', 'block');
            });

            // Hides the unordered list when a list item is clicked and updates the styled div to show the selected list item
            // Updates the select element to have the value of the equivalent option

            $listItems.click(function (e) {
                _$($listItems).removeClass('selection');
                e.stopPropagation();
                if(selectOnlyTextFormatDesktop){
                    $styledSelect.text('Formato da ' + _$(this).text());
                }else if(selectOnlyTextQtyMobile){
                    $styledSelect.text('Qtà ' + _$(this).text());
                }else{
                    $styledSelect.text(_$(this).text()).removeClass('active')
                }
                
                $this.val(_$(this).attr('rel'));
                _$(this).addClass('selection');
                $list.hide();

                if($this.val()) {
                    $styledSelect.parents('.js-select-custom').removeClass('empty');
                } else {
                    $styledSelect.parents('.js-select-custom').addClass('empty');
                }

                // trigger select
                $this.trigger("change");

                _this.customSelectValue($this.attr('id'), $this.val());
            });

            // Hides the unordered list when clicking outside of it
            _$(document).click(function(e) {
                if (!(_$(e.target).attr('class') === 'mCSB_buttonUp' 
                    || _$(e.target).attr('class') === 'mCSB_buttonDown' 
                    || _$(e.target).attr('class') === 'mCSB_draggerContainer'
                    || _$(e.target).attr('class') === 'mCSB_dragger_bar'
                    || _$(e.target).attr('class') === 'mCustomScrollBox mCS-dark mCSB_vertical mCSB_inside'
                    || _$(e.target).attr('class') === 'mCSB_draggerRail')) {
                    $styledSelect.removeClass('active');
                    $list.hide();
                }   
            });
        });
        this.initSingleCustomScrollbar(customSelect);
        _$(customSelect).find('.styledSelect').trigger('click');

        let currentQuantity = _$(customSelect).find('select option.selection').text() ? parseInt(_$(customSelect).find('select option.selection').text()) : 0;
        if(dataProduct && dataProduct.volume && Window.volume && Window.volume.cartVolume > -1 && Window.volume.maxVolume){
            let maxHeavyOpts = currentQuantity + Math.floor((Window.volume.maxVolume - Window.volume.cartVolume) / dataProduct.volume);
            if(maxHeavyOpts < maxQty){
                _$(customSelect).find(`li:lt(${maxHeavyOpts + 1})`).removeClass('uk-hidden');
                _$(customSelect).find(`li:gt(${maxHeavyOpts})`).addClass('uk-hidden');
            }
        }
    }

    initSingleCustomScrollbar(e: object){
        //@ts-ignore
        _$(e).find('.box-overflow-arrow').mCustomScrollbar({
            theme: 'dark',
            mouseWheel: {
                enable: true,
                scrollAmount: 50,
                deltaFactor: 50,
                preventDefault: true
            },
            scrollButtons: { enable: true },
            keyboard: {enable: true},
            scrollInertia: 60,
            contentTouchScroll: 200,
            documentTouchScroll: false,
            advanced: {
                autoScrollOnFocus: false,
                updateOnImageLoad: false,
            },
            scrollEasing: 'linear'
        });

        //@ts-ignore
        _$(e).find('.box-overflow').mCustomScrollbar({
            theme: 'dark',
            mouseWheel: {
                enable: true,
                scrollAmount: 50,
                deltaFactor: 50,
                preventDefault: true
            },
            scrollButtons: { enable: true },
            keyboard: {enable: true},
            scrollInertia: 60,
            contentTouchScroll: 200,
            documentTouchScroll: false,
            advanced: {
                autoScrollOnFocus: false,
                updateOnImageLoad: false,
            },
            scrollEasing: 'linear'
        });

    }

    /**
     * Controlli custom sui Valori delle Select
     * @param e
     * @param val
     */
    customSelectValue(e, val: any) {
        
        switch (e) {
            case 'envoiceSelection': //fatturazione elettronica - seleziona intestarario

                let form = _$('#envoiceForm');
                form.find('.uk-label-float').removeClass('uk-hidden');
                form.find('.uk-label-float .opt').removeClass('uk-hidden');
                form.find('.sub-title-azienda').addClass('uk-hidden');

                //reset required field
                form.find('input').prop('required', false);
                form.find('input#f_etichetta').prop('required', true);
                form.find('input#f_address').prop('required', true);
                form.find('input#f_cap').prop('required', true);
                form.find('input#f_city').prop('required', true);
                form.find('select#f_provincia').prop('required', true);
                form.find('select#f_nazione').prop('required', true);
                if (val === 'azienda') {
                    //azienda
                    form.find('.form-piva').removeClass('uk-hidden');
                    form.find('.form-pec .opt').addClass('uk-hidden');
                    form.find('.form-cdestinatario .opt').addClass('uk-hidden');
                    form.find('.sub-title-azienda').removeClass('uk-hidden');

                    form.find('input#f_name').prop('required', true);
                    form.find('input#f_surname').prop('required', true);
                    form.find('input#f_rsociale').prop('required', true);
                    form.find('input#f_piva').prop('required', true);
                    form.find('input#f_pec').prop('required', true);
                    form.find('input#f_cdestinatario').prop('required', true);
                    form.find('input#f_cf').prop('required', true);
                    form.find('input#f_cf').attr('pattern','.+');

                } else if (val === 'persfis') {
                    //persona fisica
                    form.find('.form-piva').addClass('uk-hidden');
                    form.find('.form-mail .opt').addClass('uk-hidden');
                    form.find('.form-rsociale').addClass('uk-hidden');

                    form.find('input#f_name').prop('required', true);
                    form.find('input#f_surname').prop('required', true);
                    form.find('input#f_cf').prop('required', true);
                    form.find('input#f_cf').attr('pattern','^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$');
                    form.find('input#f_mail').prop('required', true);

                }

                form.find('input:empty:not(:required)').removeClass('uk-form-danger');
                form.find('input:empty:not(:required)').parent().find('.message-error').addClass('uk-hidden');

                this.checkFormValidator('submitEnvoiceData', 'envoiceForm');
                break;
            default:
                break;
        }
    }

    getQueryValue() {
        var results = new RegExp('[?&]query=([^&#]*)').exec(window.location.href);
        if (results == null) {
            return null;
        } else {
            return decodeURI(results[1]) || 0;
        }
    }

    managePopupServiceUnavailable() {
        if (Window.typeOfService && Window.pointOfService) {
            if (Window.typeOfService == 'HOME_DELIVERY' && !Window.pointOfService.enabled) {
                /* Check presenza del pdv di backup */
                if(Window.pointOfService.backupPos){
                    /* Popolare dinamicamente la modale */
                    _Uikit.modal('#modalServizioNonDisponibile').show();
                }else{
                    _Uikit.modal('#modalCambiaIndirizzo').show();
                }
            } else if (Window.typeOfService == 'ORDER_AND_COLLECT' && !Window.pointOfService.enabled) {
                /* Check presenza del pdv di backup */
                if(Window.pointOfService.backupPos){
                    /* Popolare dinamicamente la modale */
                    /*  per ordina e ritira non dovrebbe servire mai */
                    _Uikit.modal('#modalServizioNonDisponibile').show();
                }else{
                    _Uikit.modal('#modalCambiaIndirizzo').show();
                }
            }
        }
    }

    showModalWhenNotLogged(){
        if(Window.interactionCondition === 'REQUIRE_SERVICE_CHOICE' && !Window.typeOfService){
            if(_$('body').find('.alert-accedi-scegli-servizio').is(':visible')) {
                _$('body').addClass('no-typeofservice-selected');
            }

            /* rimuove il click sull'href che porta alla pagina prodotto */
            _$('.component-ProductCard').find('a.product').css('pointer-events', 'none');

            const mainContent = document.querySelector(".main-content");
            if (!mainContent) return;
            mainContent.addEventListener("click", (event) => {
                if(!this.inGooglePageWhitelist(event)){
                    event.preventDefault();
                    event.stopPropagation();
                    if(!this.draggedByScrollbar){
                        if(this.isMobile() || this.isTablet()){
                            if(_$(event.target).closest('.component-ProductCard').length && _$(event.target).closest('.component-ProductCard').attr('data-clicked') != 'clicked'){
                                _$('.component-ProductCard').attr('data-clicked', 'not-clicked');
                                _$(event.target).closest('.component-ProductCard').attr('data-clicked', 'clicked');
                                if(!_$(event.target).closest('.component-ProductCard').find('.alert-pdv-google').length){
                                    console.log('no alert pdv google')
                                    _$(event.target).closest('.component-ProductCard').attr('data-clicked', 'not-clicked');
                                    this.showModalOnboardingStep0();
                                }
                            }else{
                                _$('.component-ProductCard').attr('data-clicked', 'not-clicked');
                                this.showModalOnboardingStep0();
                            }
                        }else{
                            this.showModalOnboardingStep0();
                        }
                    }
                }
            }, { capture: true });
        }
    }

    showModalOnboardingStep0(){
        _$('#modal-onboarding').find('#modal-onboarding-wrapper').hide();
        _$('#modal-onboarding').find('#modal-onboarding-wrapper-googleuser').show();
        _Uikit.modal('#modal-onboarding').show();
        this.sendAnalytics('1.7.2', 'pageview');
    }
    
    inGooglePageWhitelist(event): boolean{

        if(_$(event.target).length){
            let inWhitelist = false;
            this.googlePageWhitelistClasses.forEach((val, index) => {
                if(_$(event.target).closest('.' + val).length){
                    inWhitelist = true;
                }
            });
            return inWhitelist;
        }else{
            return false
        }
    }

    /**
     * Link to MyConad Pages
     * @param page
     */
    goToMyConad(page, myUrl?) {
        let url = myUrl ? encodeURIComponent(myUrl) : encodeURIComponent(Window.location.href);

        switch (page) {
            case 'login':
                //TODO: Analytics - inserire tracciamento login
                Window.location.href = Window.urlSite.myConadLogin + '?cb=' + url;
                break;

            case 'register':
                //TODO: Analytics - inserire tracciamento registrazione
                Window.location.href = Window.urlSite.myConadRegister + '?cb=' + url;
                break;
            default:
                return null;
        }
    }

    showNotification(message, status) {
        if(Window.DEV_MODE) {
            _Uikit.notification({
                message: message,
                status: status,
                pos: 'bottom-right',
                timeout: 5000,
            });
        }
    }

    managePreferiteIcons(productId) {
        let allCards = _$('.component-ProductCard, .component-ProductCardLastView, .component-CardSuggest');
        let prodIdCard;
        let e;
        let objData;
        let objDataText;
        let currentCard;
    
        for (let i = 0; i < allCards.length; i++) {
            currentCard = allCards.eq(i);
            if(_$(currentCard).attr('data-product')){
                objDataText = _$(currentCard).attr('data-product');
                objData = JSON.parse(objDataText);
                prodIdCard = objData.code;
                if (prodIdCard == productId) {
                    e = _$(currentCard).find('.manage-preferite-product');
                    _$(e).toggleClass('active');
                }
            }
        }
    }

    managePreferiteAriaLabel() {
        if(_$('.manage-preferite-product').length) {
            if(_$('.manage-preferite-product').hasClass('active')) {
                _$('.manage-preferite-product').attr('aria-label', 'Rimuovi dai preferiti');
            } else {
                _$('.manage-preferite-product').attr('aria-label', 'Aggiungi ai preferiti');
            }
        }
    }

    checkCartStatus() {
        /* settaggio parametri cards */
        if(_$('.component-ProductCard').length) {
            _$('.component-ProductCard').each(function() {
                if (_$(this).attr('data-product')) {
                    let dataProduct = JSON.parse(_$(this).attr('data-product'));
                    let scaleMap = {
                        "MG": "mg",
                        "CG": "cg",
                        "GR": "g",
                        "HG": "hg",
                        "KG": "kg"
                    }
                    _$(this).attr('data-code', dataProduct.code);
                    if (dataProduct.entries) {
                        /* Peso incrementale */
                        if(dataProduct.increment){
                            let entry = dataProduct.entries[0];
                            if(dataProduct.increment.unitOfMeasure && dataProduct.increment.unitOfMeasure == 'KG'){
                                _$(this).find('.add-quantity .quantity').html(entry.scale < 1 ? parseFloat((entry.scale * 1000).toFixed(4)).toString() + ' ' + scaleMap['GR'] : entry.scale + ' ' + scaleMap['KG']);
                            }else if(dataProduct.increment.unitOfMeasure && dataProduct.increment.unitOfMeasure == 'GR'){
                                _$(this).find('.add-quantity .quantity').html(entry.scale >= 1000 ? entry.scale/1000 + ' ' + scaleMap['KG'] : entry.scale + ' ' + scaleMap['GR']);
                            }else{
                                _$(this).find('.add-quantity .quantity').html(entry.scale >= 1000 ? entry.scale/1000 + ' ' + scaleMap['KG'] : entry.scale + ' ' + scaleMap['GR']);
                            }
                            _$(this).find('.add-quantity .price').html(entry.totalPrice.value.toFixed(2).toString().replace('.', ',')+'€');
                            _$(this).find('.add-quantity').removeClass('uk-hidden');
                            _$(this).find('.add-product').addClass('uk-hidden');
                            _$(this).attr('data-scale', entry.scale);
                            _$(this).attr('data-entryNumber', entry.entryNumber);
                        }
                        else if(dataProduct.variations){
                            _$(this).attr('data-currentPrice', 0);
                            let entries = dataProduct.entries;
                            let totalQty = 0;
                            let dataQuantity = {}
                            let dataEntryNumber = {}
                            let dataCurrentPrice = JSON.parse(_$(this).attr('data-currentPrice'));
                            for(let entry in entries){
                                dataQuantity[entries[entry].scale] = entries[entry].qty;
                                dataEntryNumber[entries[entry].scale] = entries[entry].entryNumber;
                                dataCurrentPrice += entries[entry].totalPrice.value;
                                totalQty += entries[entry].qty * 1000 * entries[entry].scale;
                            }

                            totalQty = totalQty / 1000;

                            _$(this).attr('data-quantity', JSON.stringify(dataQuantity));
                            _$(this).attr('data-entryNumber', JSON.stringify(dataEntryNumber));
                            _$(this).attr('data-currentPrice', dataCurrentPrice);
                            if(dataProduct.variations.unitOfMeasure == "KG"){
                                _$(this).find('.add-quantity .quantity').html((totalQty < 1 ? parseFloat((totalQty * 1000).toFixed(4)) : parseFloat((totalQty).toFixed(4))).toString() +' '+ (totalQty < 1 ? 'g' : 'kg'));
                            }else if(dataProduct.variations.unitOfMeasure == "GR"){
                                _$(this).find('.add-quantity .quantity').html((totalQty >= 1000 ? totalQty/1000 : totalQty).toString() +' '+ (totalQty >= 1000 ? 'kg' : 'g'));
                            }else{
                                _$(this).find('.add-quantity .quantity').html((totalQty >= 1000 ? totalQty/1000 : totalQty).toString() +' '+ (totalQty >= 1000 ? 'kg' : 'g'));
                            }
                            _$(this).find('.add-quantity .price').html('€ '+parseFloat(_$(this).attr('data-currentPrice')).toFixed(2).toString());
                            _$(this).find('.add-product').addClass("uk-hidden");
                            _$(this).find('.add-quantity').removeClass("uk-hidden");

                        }else if(!_$(this).attr('data-quantity')){
                            let entry = dataProduct.entries[0];
                            _$(this).find('.add-quantity .quantity').html(entry.qty);
                            _$(this).find('.add-quantity .price').html(entry.totalPrice.value.toFixed(2).toString().replace('.', ',')+'€');
                            _$(this).find(".add-quantity").removeClass("uk-hidden");
                            _$(this).find(".add-product").addClass("uk-hidden");
                            _$(this).attr("data-quantity", entry.qty);
                            _$(this).attr("data-entryNumber", entry.entryNumber);
                            if(dataProduct.promo){
                                if(dataProduct.promo.length > 0){
                                    let promoAlertNeeded = false;
                                    let contingency = false;
                                    let activePromo = {};
                                    if(dataProduct.promo){
                                        let promoArray = dataProduct.promo;
                                        promoArray.forEach((index, element) => {
                                            if(promoArray[element].itOrderSap && !contingency){
                                                contingency = true;
                                                promoAlertNeeded = true;
                                                activePromo = promoArray[element];
                                            }else if(['PRODUCT_PARTNER_FIXED_DISCOUNT',
                                                'PRODUCT_PARTNER_MIX_FIXED_DISCOUNT',
                                                'PRODUCT_N_FIXED_DISCOUNT',
                                                'PRODUCT_ADDITIONAL_PERCENTAGE_DISCOUNT'].indexOf(promoArray[element].promoType) != -1 && !promoAlertNeeded){
                                                promoAlertNeeded = true;
                                                activePromo = promoArray[element];
                                            }
                                        });

                                        if(contingency){
                                            /* promo contingency */
                                            if(entry.qty > 0){
                                                _$(this).find('.add-quantity .total .price').prepend(`<div class="price-old">€ ${(activePromo['basePrice'] * entry.qty).toFixed(2).toString().replace('.', ',')}</div>`);
                                            }
                                        }else if(promoAlertNeeded){
                                            let missingForPromo = (activePromo['buyX'] + activePromo['getY']) - entry.qty%(activePromo['buyX'] + activePromo['getY']);
                                            if(entry.qty > 0 && missingForPromo == (activePromo['buyX'] + activePromo['getY'])){
                                                if(activePromo['promoType'] != 'PRODUCT_PARTNER_MIX_FIXED_DISCOUNT'){
                                                    _$(this).find('.add-quantity .total .price').prepend(`<div class="price-old">€ ${(activePromo['basePrice'] * entry.qty).toFixed(2).toString().replace('.', ',')}</div>`);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    /* Mostra alert prodotti pesanti */
                    if(dataProduct.volume){
                        if(dataProduct.volume + Window.volume.cartVolume > Window.volume.maxVolume){
                            _$(this).find('.alert-heavy-warning').addClass('uk-hidden');
                            _$(this).find('.alert-contingency-warning').addClass('uk-hidden');
                            _$(this).find('.alert-heavy-block-offcart').addClass('uk-hidden');
                            _$(this).find('.alert-heavy-block-oncart').addClass('uk-hidden');
                            dataProduct.entries != null ? _$(this).find('.alert-heavy-block-oncart').removeClass('uk-hidden') : _$(this).find('.alert-heavy-block-offcart').removeClass('uk-hidden');
                            _$(this).find('.add-quantity .add-quantity-button:last-child').prop('disabled', true);
                            _$(this).find('.add-product').prop('disabled', true);
                        }else if(Window.volume.cartVolume >= Window.volume.maxVolumeNoSurcharge){
                            _$(this).find('.alert-heavy-warning').addClass('uk-hidden');
                            _$(this).find('.alert-heavy-block-offcart').addClass('uk-hidden');
                            _$(this).find('.alert-heavy-block-oncart').addClass('uk-hidden');
                            _$(this).find('.add-quantity .add-quantity-button:last-child').prop('disabled', false);
                            _$(this).find('.add-product').prop('disabled', false);
                        }else{
                            _$(this).find('.alert-heavy-warning').addClass('uk-hidden');
                            _$(this).find('.alert-contingency-warning').addClass('uk-hidden');
                            _$(this).find('.alert-heavy-block-offcart').addClass('uk-hidden');
                            _$(this).find('.alert-heavy-block-oncart').addClass('uk-hidden');
                            _$(this).find('.add-quantity .add-quantity-button:last-child').prop('disabled', false);
                            _$(this).find('.add-product').prop('disabled', false);
                        }
                    }
                }
            });
        }

        /* Settaggio parametri card detail cart */
        let heavyCartLimit = false;
        let heavyCartSurcharge = false;
        if(_$('.component-ProductCardDetailCart').length) {
            _$('.component-ProductCardDetailCart').each(function(){
                if (_$(this).attr('data-product')) {
                    
                    let dataProduct = JSON.parse(_$(this).attr('data-product'));
                    let dataQuantity = parseFloat(_$(this).attr('data-quantity'));

                    _$(this).attr('data-code', dataProduct.code);
                    if (dataProduct.entries) {
                        let entry = dataProduct.entries[0];
                        _$(this).attr("data-quantity", entry.qty);
                        _$(this).attr("data-entryNumber", entry.entryNumber);
                        if (dataProduct.increment || dataProduct.variations) {
                            _$(this).attr('data-scale', entry.scale);
                        }
                    }

                    if (dataProduct.volume && Window.volume) {
                        if(Window.volume.cartVolume && Window.volume.maxVolume){
                            let volumeDelta = Window.volume.maxVolume - Window.volume.cartVolume;
                            let maxNewQuantity = Math.floor(volumeDelta / dataProduct.volume);
                            if (maxNewQuantity >= 1) {
                                let maxOptions = dataQuantity + maxNewQuantity;
                                _$(this).find(`.quantity .select-native-ui`).each(function() {
                                    _$(this).find(`option:lt(${maxOptions + 1})`).removeClass('uk-hidden');
                                    _$(this).find(`option:gt(${maxOptions})`).addClass('uk-hidden');
                                });
                            } else {
                                let maxOptions = dataQuantity;
                                _$(this).find(`.quantity .select-native-ui`).each(function() {
                                    _$(this).find(`option:lt(${maxOptions + 1})`).removeClass('uk-hidden');
                                    _$(this).find(`option:gt(${maxOptions})`).addClass('uk-hidden');
                                });
                            }
                        }

                        if (Window.volume.cartVolume >= Window.volume.maxVolume) {
                            heavyCartLimit = true;
                        } else if (Window.volume.cartVolume >= Window.volume.maxVolumeNoSurcharge) {
                            heavyCartSurcharge = true;
                        }
                    }
                }
            });
        }

        /* Settaggio parametri card last view */
        if(_$('.component-ProductCardLastView').length){
            _$('.component-ProductCardLastView').each(function(){
                if (_$(this).attr('data-product')) {

                    let dataProduct = JSON.parse(_$(this).attr('data-product'));
                    _$(this).attr('data-code', dataProduct.code);

                    if (dataProduct.entries) {
                        if (dataProduct.variations) {
                            let entries = dataProduct.entries;
                            let totalQty = 0;
                            let totalUnits = 0;
                            let dataQuantity = {}
                            let dataEntryNumber = {}
                            for (let entry in entries) {
                                dataQuantity[entries[entry].scale] = entries[entry].qty;
                                dataEntryNumber[entries[entry].scale] = entries[entry].entryNumber;
                                totalQty += entries[entry].qty * entries[entry].scale;
                                totalUnits += entries[entry].qty;
                            }
                            _$(this).attr('data-quantity', JSON.stringify(dataQuantity));
                            _$(this).attr('data-entryNumber', JSON.stringify(dataEntryNumber));

                            if(dataProduct.variations.unitOfMeasure == "KG"){
                                _$(this).find('.add-cart .uk-badge').html((totalQty < 1 ? parseFloat((totalQty * 1000).toFixed(4)) : parseFloat((totalQty).toFixed(4))).toString() +' '+ (totalQty < 1 ? 'g' : 'kg'));
                            }else if(dataProduct.variations.unitOfMeasure == "GR"){
                                _$(this).find('.add-cart .uk-badge').html((totalQty >= 1000 ? totalQty/1000 : totalQty).toString() +' '+ (totalQty >= 1000 ? 'kg' : 'g'));
                            }else{
                                _$(this).find('.add-cart .uk-badge').html((totalQty >= 1000 ? totalQty/1000 : totalQty).toString() +' '+ (totalQty >= 1000 ? 'kg' : 'g'));
                            }

                            _$(this).find('.add-cart .uk-badge').removeClass('uk-hidden');
                        } else {
                            let entry = dataProduct.entries[0];
                            _$(this).attr("data-quantity", entry.qty);
                            _$(this).attr('data-entryNumber', entry.entryNumber);
                            if (dataProduct.increment || dataProduct.variations) {
                                _$(this).attr('data-scale', entry.scale);
                            }

                            if(dataProduct.increment){
                                if(dataProduct.increment.unitOfMeasure && dataProduct.increment.unitOfMeasure == 'KG'){
                                    _$(this).find('.add-cart .uk-badge').html(entry.scale < 1 ? parseFloat((entry.scale * 1000).toFixed(4)).toString() + ' ' + 'g' : entry.scale + ' ' + 'kg');
                                }else if(dataProduct.increment.unitOfMeasure && dataProduct.increment.unitOfMeasure == 'GR'){
                                    _$(this).find('.add-cart .uk-badge').html(entry.scale >= 1000 ? entry.scale/1000 + ' ' + 'kg' : entry.scale + ' ' + 'g');
                                }else{
                                    _$(this).find('.add-cart .uk-badge').html(entry.scale >= 1000 ? entry.scale/1000 + ' ' + 'kg' : entry.scale + ' ' + 'g');
                                }
                            }else{
                                _$(this).find('.add-cart .uk-badge').html(entry.qty);
                            }
                            _$(this).find('.add-cart .uk-badge').removeClass('uk-hidden');
                        }
                    }

                    if (dataProduct.volume) {
                        if (dataProduct.volume + Window.volume.cartVolume > Window.volume.maxVolume) {
                            _$(this).find('.add-cart').prop('disabled', true);
                        } else if (Window.volume.cartVolume >= Window.volume.maxVolumeNoSurcharge) {
                            _$(this).find('.add-cart').prop('disabled', false);
                        } else {
                            _$(this).find('.add-cart').prop('disabled', false);
                        }
                    }
                }
            });
        }

        /* Settaggio parametri card suggest - homepage */
        if(_$('.component-CardSuggest').length){
            _$('.component-CardSuggest').each(function(){
                if (_$(this).attr('data-product')) {
                    let dataProduct = JSON.parse(_$(this).attr('data-product'));
                    _$(this).attr('data-code', dataProduct.code);

                    if (dataProduct.entries) {
                        if (dataProduct.variations) {
                            let entries = dataProduct.entries;
                            let totalQty = 0;
                            let totalUnits = 0;
                            let dataQuantity = {}
                            let dataEntryNumber = {}
                            for (let entry in entries) {
                                dataQuantity[entries[entry].scale] = entries[entry].qty;
                                dataEntryNumber[entries[entry].scale] = entries[entry].entryNumber;
                                totalQty += entries[entry].qty * entries[entry].scale;
                                totalUnits += entries[entry].qty;
                            }
                            _$(this).attr('data-quantity', JSON.stringify(dataQuantity));
                            _$(this).attr('data-entryNumber', JSON.stringify(dataEntryNumber));

                            if(dataProduct.variations.unitOfMeasure == "KG"){
                                _$(this).find('.add-cart .uk-badge').html((totalQty < 1 ? parseFloat((totalQty * 1000).toFixed(4)) : parseFloat((totalQty).toFixed(4))).toString() +' '+ (totalQty < 1 ? 'g' : 'kg'));
                            }else if(dataProduct.variations.unitOfMeasure == "GR"){
                                _$(this).find('.add-cart .uk-badge').html((totalQty >= 1000 ? totalQty/1000 : totalQty).toString() +' '+ (totalQty >= 1000 ? 'kg' : 'g'));
                            }else{
                                _$(this).find('.add-cart .uk-badge').html((totalQty >= 1000 ? totalQty/1000 : totalQty).toString() +' '+ (totalQty >= 1000 ? 'kg' : 'g'));
                            }

                            _$(this).find('.add-cart .uk-badge').removeClass('uk-hidden');

                        } else {
                            let entry = dataProduct.entries[0];
                            _$(this).attr("data-quantity", entry.qty);
                            _$(this).attr('data-entryNumber', entry.entryNumber);
                            if (dataProduct.increment || dataProduct.variations) {
                                _$(this).attr('data-scale', entry.scale);
                            }

                            if(dataProduct.increment){
                                if(dataProduct.increment.unitOfMeasure && dataProduct.increment.unitOfMeasure == 'KG'){
                                    _$(this).find('.add-cart .uk-badge').html(entry.scale < 1 ? parseFloat((entry.scale * 1000).toFixed(4)).toString() + ' ' + 'g' : entry.scale + ' ' + 'kg');
                                }else if(dataProduct.increment.unitOfMeasure && dataProduct.increment.unitOfMeasure == 'GR'){
                                    _$(this).find('.add-cart .uk-badge').html(entry.scale >= 1000 ? entry.scale/1000 + ' ' + 'kg' : entry.scale + ' ' + 'g');
                                }else{
                                    _$(this).find('.add-cart .uk-badge').html(entry.scale >= 1000 ? entry.scale/1000 + ' ' + 'kg' : entry.scale + ' ' + 'g');
                                }
                            }else{
                                _$(this).find('.add-cart .uk-badge').html(entry.qty);
                            }
                            _$(this).find('.add-cart .uk-badge').removeClass('uk-hidden');
                        }
                    }

                    /* Mostra alert prodotti pesanti */
                    if (dataProduct.volume) {
                        if (dataProduct.volume + Window.volume.cartVolume > Window.volume.maxVolume) {
                            _$(this).find('.add-cart').prop('disabled', true);
                        } else if (Window.volume.cartVolume >= Window.volume.maxVolumeNoSurcharge) {
                            _$(this).find('.add-cart').prop('disabled', false);
                        } else {
                            _$(this).find('.add-cart').prop('disabled', false);
                        }
                    }
                }
            });
        }

        if(_$('body').attr('nkPage') == 'DetailCart'){
            if(heavyCartLimit){
                _$('#heavy-limit-cart-mobile, #heavy-limit-cart-desktop').removeClass('uk-hidden');
                _$('#heavy-surcharge-cart-mobile, #heavy-surcharge-cart-desktop').addClass('uk-hidden');
            }else if(heavyCartSurcharge){
                _$('#heavy-limit-cart-mobile, #heavy-limit-cart-desktop').addClass('uk-hidden');
                _$('#heavy-surcharge-cart-mobile, #heavy-surcharge-cart-desktop').removeClass('uk-hidden');
            }else{
                _$('#heavy-limit-cart-mobile, #heavy-limit-cart-desktop').addClass('uk-hidden');
                _$('#heavy-surcharge-cart-mobile, #heavy-surcharge-cart-desktop').addClass('uk-hidden')
            }
        }
    }
    /* Aggiornamento card in pagina - data-quantity, data-entrynumber, data-scale  */
    /* prende l'input delle entries dal manage-carts e aggiorna tutte le card in pagina */
    updateProductsOnPage(entries: Array<any>){
        if(_$('body').hasClass('page-DetailCart')){

            if(_$('.component-ProductCardDetailCart').length > 0) {
                _$('.component-ProductCardDetailCart').each(function(){
                    if (_$(this).attr('data-product')) {
                        let dataProduct = JSON.parse(_$(this).attr('data-product'));
                        let dataQuantity = parseFloat(_$(this).attr('data-quantity'));

                        if (dataProduct.volume && Window.volume) {
                            if(Window.volume.maxVolume && Window.volume.cartVolume){
                                let volumeDelta = Window.volume.maxVolume - Window.volume.cartVolume;
                                let maxNewQuantity = Math.floor(volumeDelta / dataProduct.volume);
                                if (maxNewQuantity >= 1) {
                                    let maxOptions = dataQuantity + maxNewQuantity;
                                    _$(this).find(`.quantity .select-native-ui`).each(function() {
                                        _$(this).find(`option:lt(${maxOptions + 1})`).removeClass('uk-hidden');
                                        _$(this).find(`option:gt(${maxOptions})`).addClass('uk-hidden');
                                    });
                                } else {
                                    let maxOptions = dataQuantity;
                                    _$(this).find(`.quantity .select-native-ui`).each(function() {
                                        _$(this).find(`option:lt(${maxOptions + 1})`).removeClass('uk-hidden');
                                        _$(this).find(`option:gt(${maxOptions})`).addClass('uk-hidden');
                                    });
                                }
                            }
                        }
                    }
                });

                let heavyCartSurcharge = false;
                let heavyCartLimit = false;

                if(Window.volume.cartVolume >= Window.volume.maxVolume){
                    heavyCartLimit = true;
                }else if(Window.volume.cartVolume >= Window.volume.maxVolumeNoSurcharge){
                    heavyCartSurcharge = true;
                }

                if(_$('body').attr('nkPage') == 'DetailCart'){
                    if(heavyCartLimit){
                        _$('#heavy-limit-cart-mobile, #heavy-limit-cart-desktop').removeClass('uk-hidden');
                        _$('#heavy-surcharge-cart-mobile, #heavy-surcharge-cart-desktop').addClass('uk-hidden');
                    }else if(heavyCartSurcharge){
                        _$('#heavy-limit-cart-mobile, #heavy-limit-cart-desktop').addClass('uk-hidden');
                        _$('#heavy-surcharge-cart-mobile, #heavy-surcharge-cart-desktop').removeClass('uk-hidden');
                    }else{
                        _$('#heavy-limit-cart-mobile, #heavy-limit-cart-desktop').addClass('uk-hidden');
                        _$('#heavy-surcharge-cart-mobile, #heavy-surcharge-cart-desktop').addClass('uk-hidden')
                    }
                }
            }else{
                window.location.reload();
            }
        }

        entries.forEach((value) => {
            let productCode = value.productCode;
            let quantity = value.quantity;
            let scale = value.scale ? value.scale : undefined;
            let entryNumber = value.entryNumber;

            /* Product card */
            if(_$('.component-ProductCard[data-code='+productCode+']').length) {
                if(_$('.component-ProductCard').attr('data-product')) {
                    let isVariable;
                    let dataProduct = JSON.parse(_$('.component-ProductCard[data-code='+productCode+']').attr('data-product'));
                    if(dataProduct.variations){
                        isVariable = true;
                    }

                    if(isVariable){
                        let entryNumberVariable;
                        let quantityVariable;
                        if(_$('.component-ProductCard[data-code='+productCode+']').attr('data-entryNumber')){
                            entryNumberVariable = JSON.parse(_$('.component-ProductCard[data-code='+productCode+']').attr('data-entryNumber'));
                        }else{
                            entryNumberVariable = {};
                        }

                        if(_$('.component-ProductCard[data-code='+productCode+']').attr('data-quantity')){
                            quantityVariable = JSON.parse(_$('.component-ProductCard[data-code='+productCode+']').attr('data-quantity'));
                        }else{
                            quantityVariable = {}
                        }

                        entryNumberVariable[scale] = entryNumber;
                        quantityVariable[scale] = quantity;
                        _$('.component-ProductCard[data-code='+productCode+']').attr('data-entryNumber', JSON.stringify(entryNumberVariable));
                        _$('.component-ProductCard[data-code='+productCode+']').attr('data-quantity', JSON.stringify(quantityVariable));
                    }else{
                        _$('.component-ProductCard[data-code='+productCode+']').attr('data-entryNumber', entryNumber);
                        _$('.component-ProductCard[data-code='+productCode+']').attr('data-quantity', quantity);
                        if(scale){
                            _$('.component-ProductCard[data-code='+productCode+']').attr('data-scale', scale);
                        }
                    }

                    /* Disabilita l'aggiunta nel caso di prodotti pesanti che supererebbero la soglia */
                    _$('.component-ProductCard[data-code='+productCode+']').each((index, element) => {
                        let dataProduct = JSON.parse(_$(element).attr('data-product'));
                        if(dataProduct.volume){
                            if(dataProduct.volume + Window.volume.cartVolume > Window.volume.maxVolume){
                                _$(element).find('.alert-heavy-warning').addClass('uk-hidden');
                                _$(element).find('.alert-contingency-warning').addClass('uk-hidden');
                                _$(element).find('.alert-heavy-block-offcart').addClass('uk-hidden');
                                _$(element).find('.alert-heavy-block-oncart').addClass('uk-hidden');
                                _$(element).find('.alert-heavy-block-oncart').removeClass('uk-hidden');
                                _$(element).find('.add-quantity .add-quantity-button:last-child').prop('disabled', true);
                                _$(element).find('.add-product').prop('disabled', true);
                            }else if(Window.volume.cartVolume >= Window.volume.maxVolumeNoSurcharge){
                                _$(element).find('.alert-contingency-warning').addClass('uk-hidden');
                                _$(element).find('.alert-heavy-block-offcart').addClass('uk-hidden');
                                _$(element).find('.alert-heavy-block-oncart').addClass('uk-hidden');
                                _$(element).find('.add-quantity .add-quantity-button:last-child').prop('disabled', false);
                                _$(element).find('.add-product').prop('disabled', false);
                            }else{
                                _$(element).find('.alert-heavy-warning').addClass('uk-hidden');
                                _$(element).find('.alert-heavy-block-offcart').addClass('uk-hidden');
                                _$(element).find('.alert-heavy-block-oncart').addClass('uk-hidden');
                                _$(element).find('.add-quantity .add-quantity-button:last-child').prop('disabled', false);
                                _$(element).find('.add-product').prop('disabled', false);
                            }
                        }
                    });
                }

            }

            /* Product card last view */
            if(_$('.component-ProductCardLastView[data-code='+productCode+']').length) {
                if(_$('.component-ProductCardLastView').attr('data-product')) {
                    let isVariable;
                    let dataProduct = JSON.parse(_$('.component-ProductCardLastView[data-code=' + productCode + ']').attr('data-product'));
                    if (dataProduct.variations) {
                        isVariable = true;
                    }

                    if (isVariable) {
                        let entryNumberVariable;
                        let quantityVariable;
                        if (_$('.component-ProductCardLastView[data-code=' + productCode + ']').attr('data-entryNumber')) {
                            entryNumberVariable = JSON.parse(_$('.component-ProductCardLastView[data-code=' + productCode + ']').attr('data-entryNumber'));
                        } else {
                            entryNumberVariable = {};
                        }

                        if (_$('.component-ProductCardLastView[data-code=' + productCode + ']').attr('data-quantity')) {
                            quantityVariable = JSON.parse(_$('.component-ProductCardLastView[data-code=' + productCode + ']').attr('data-quantity'));
                        } else {
                            quantityVariable = {}
                        }

                        entryNumberVariable[scale] = entryNumber;
                        quantityVariable[scale] = quantity;
                        _$('.component-ProductCardLastView[data-code=' + productCode + ']').attr('data-entryNumber', JSON.stringify(entryNumberVariable));
                        _$('.component-ProductCardLastView[data-code=' + productCode + ']').attr('data-quantity', JSON.stringify(quantityVariable));
                    } else {
                        _$('.component-ProductCardLastView[data-code=' + productCode + ']').attr('data-entryNumber', entryNumber);
                        _$('.component-ProductCardLastView[data-code=' + productCode + ']').attr('data-quantity', quantity);
                        if (scale) {
                            _$('.component-ProductCardLastView[data-code=' + productCode + ']').attr('data-scale', scale);
                        }
                    }
                    /* Disabilita l'aggiunta nel caso di prodotti pesanti che supererebbero la soglia */
                    _$('.component-ProductCardLastView[data-code=' + productCode + ']').each((index, element) => {
                        let dataProduct = JSON.parse(_$(element).attr('data-product'));
                        if (dataProduct.volume) {
                            if (dataProduct.volume + Window.volume.cartVolume > Window.volume.maxVolume) {
                                _$(element).find('.add-cart').prop('disabled', true);
                            } else if (Window.volume.cartVolume >= Window.volume.maxVolumeNoSurcharge) {
                                _$(element).find('.add-cart').prop('disabled', false);
                            } else {
                                _$(element).find('.add-cart').prop('disabled', false);
                            }
                        }
                    });
                }
            }   

            /* Product card - card suggest */
            if(_$('.component-CardSuggest[data-code='+productCode+']').length) {
                if(_$('.component-CardSuggest').attr('data-product')) {
                    let isVariable;
                    let dataProduct = JSON.parse(_$('.component-CardSuggest[data-code=' + productCode + ']').attr('data-product'));
                    if (dataProduct.variations) {
                        isVariable = true;
                    }

                    if (isVariable) {
                        let entryNumberVariable;
                        let quantityVariable;
                        if (_$('.component-CardSuggest[data-code=' + productCode + ']').attr('data-entryNumber')) {
                            entryNumberVariable = JSON.parse(_$('.component-CardSuggest[data-code=' + productCode + ']').attr('data-entryNumber'));
                        } else {
                            entryNumberVariable = {};
                        }

                        if (_$('.component-CardSuggest[data-code=' + productCode + ']').attr('data-quantity')) {
                            quantityVariable = JSON.parse(_$('.component-CardSuggest[data-code=' + productCode + ']').attr('data-quantity'));
                        } else {
                            quantityVariable = {}
                        }

                        entryNumberVariable[scale] = entryNumber;
                        quantityVariable[scale] = quantity;
                        _$('.component-CardSuggest[data-code=' + productCode + ']').attr('data-entryNumber', JSON.stringify(entryNumberVariable));
                        _$('.component-CardSuggest[data-code=' + productCode + ']').attr('data-quantity', JSON.stringify(quantityVariable));
                    } else {
                        _$('.component-CardSuggest[data-code=' + productCode + ']').attr('data-entryNumber', entryNumber);
                        _$('.component-CardSuggest[data-code=' + productCode + ']').attr('data-quantity', quantity);
                        if (scale) {
                            _$('.component-CardSuggest[data-code=' + productCode + ']').attr('data-scale', scale);
                        }
                    }
                }
                
                /* Disabilita l'aggiunta nel caso di prodotti pesanti che supererebbero la soglia */
                _$('.component-CardSuggest[data-code='+productCode+']').each((index, element) => {
                    let dataProduct = JSON.parse(_$(element).attr('data-product'));
                    if(dataProduct.volume){
                        if(dataProduct.volume + Window.volume.cartVolume > Window.volume.maxVolume){
                            _$(element).find('.add-cart').prop('disabled', true);
                        }else if(Window.volume.cartVolume >= Window.volume.maxVolumeNoSurcharge){
                            _$(element).find('.add-cart').prop('disabled', false);
                        }else{
                            _$(element).find('.add-cart').prop('disabled', false);
                        }
                    }
                });
            }

            /* Dettaglio prodotto */
            if(_$('body').hasClass('page-DetailProduct')){
                if(Window.displayedProduct && Window.displayedProduct.code && Window.displayedProduct.code == productCode){
                    let isVariable;
                    if(Window.displayedProduct.variations){
                        isVariable = true;
                    }

                    if(isVariable){
                        let entryNumberVariable;
                        let quantityVariable;
                        if(Window._cartEntryNumber != undefined){
                            entryNumberVariable = Window._cartEntryNumber;
                        }else{
                            entryNumberVariable = {};
                        }

                        if(Window._cartQuantity){
                            quantityVariable = Window._cartQuantity;
                        }else{
                            quantityVariable = {}
                        }

                        entryNumberVariable[scale] = entryNumber;
                        quantityVariable[scale] = quantity;
                        Window._cartEntryNumber = entryNumberVariable;
                        Window._cartQuantity = quantityVariable;
                    }else{
                        Window._cartEntryNumber = entryNumber;
                        Window._cartQuantity = quantity;
                        Window._cartScale = scale;
                    }
                }
            }
        });

        if(_$('.component-ProductCard').length){
            /* Disabilita l'aggiunta nel caso di prodotti pesanti che supererebbero la soglia */
            _$('.component-ProductCard').each((index, element) => {
                if(_$(element).attr('data-product')){
                    let dataProduct = JSON.parse(_$(element).attr('data-product'));
                    if(dataProduct.volume){
                        if(dataProduct.volume + Window.volume.cartVolume > Window.volume.maxVolume){
                            _$(element).find('.alert-heavy-warning').addClass('uk-hidden');
                            _$(element).find('.alert-contingency-warning').addClass('uk-hidden');
                            _$(element).find('.alert-heavy-block-offcart').addClass('uk-hidden');
                            _$(element).find('.alert-heavy-block-oncart').addClass('uk-hidden');
                            if(_$(element).attr('data-quantity') != null && parseInt(_$(element).attr('data-quantity')) > 0){
                                _$(element).find('.alert-heavy-block-oncart').removeClass('uk-hidden');
                            }else{
                                _$(element).find('.alert-heavy-block-offcart').removeClass('uk-hidden');
                            }
                            _$(element).find('.add-quantity .add-quantity-button:last-child').prop('disabled', true);
                            _$(element).find('.add-product').prop('disabled', true);
                        }else if(Window.volume.cartVolume >= Window.volume.maxVolumeNoSurcharge){
                            _$(element).find('.alert-contingency-warning').addClass('uk-hidden');
                            _$(element).find('.alert-heavy-block-offcart').addClass('uk-hidden');
                            _$(element).find('.alert-heavy-block-oncart').addClass('uk-hidden');
                            _$(element).find('.add-quantity .add-quantity-button:last-child').prop('disabled', false);
                            _$(element).find('.add-product').prop('disabled', false);
                        }else{
                            _$(element).find('.alert-heavy-warning').addClass('uk-hidden');
                            _$(element).find('.alert-heavy-block-offcart').addClass('uk-hidden');
                            _$(element).find('.alert-heavy-block-oncart').addClass('uk-hidden');
                            _$(element).find('.add-quantity .add-quantity-button:last-child').prop('disabled', false);
                            _$(element).find('.add-product').prop('disabled', false);
                        }
                    }
                }
            });
        }

        /* Disabilita l'aggiunta nel caso di prodotti pesanti che supererebbero la soglia */
        if( _$('.component-CardSuggest').length){
            _$('.component-CardSuggest').each((index, element) => {
                if(_$(element).attr('data-product')){
                    let dataProduct = JSON.parse(_$(element).attr('data-product'));
                    if(dataProduct.volume){
                        if(dataProduct.volume + Window.volume.cartVolume > Window.volume.maxVolume){
                            _$(element).find('.add-cart').prop('disabled', true);
                        }else if(Window.volume.cartVolume >= Window.volume.maxVolumeNoSurcharge){
                            _$(element).find('.add-cart').prop('disabled', false);
                        }else{
                            _$(element).find('.add-cart').prop('disabled', false);
                        }
                    }
                }
            });
        }
        
        if(_$('.component-ProductCardLastView').length){
            /* Disabilita l'aggiunta nel caso di prodotti pesanti che supererebbero la soglia */
            _$('.component-ProductCardLastView').each((index, element) => {
                if(_$(element).attr('data-product')){
                    let dataProduct = JSON.parse(_$(element).attr('data-product'));
                    if (dataProduct.volume) {
                        if (dataProduct.volume + Window.volume.cartVolume > Window.volume.maxVolume) {
                            _$(element).find('.add-cart').prop('disabled', true);
                        } else if (Window.volume.cartVolume >= Window.volume.maxVolumeNoSurcharge) {
                            _$(element).find('.add-cart').prop('disabled', false);
                        } else {
                            _$(element).find('.add-cart').prop('disabled', false);
                        }
                    }
                }
            });
        }

        if(_$('body').hasClass('page-DetailProduct')){
            if(Window.displayedProduct && Window.displayedProduct.volume){
                if(Window.displayedProduct.volume + Window.volume.cartVolume > Window.volume.maxVolume){
                    this.hideAlertWarningPdp();
                    _$('.alert-contingency-product').addClass('uk-hidden');
                    this.showHeavyWarningPdp('.troppi-prodotti-pdp', '.limite-prodotti-pdp');
                    _$('.troppi-prodotti-pdp').parent().find('.icon-weight').removeClass('uk-hidden');
                    _$('.troppi-prodotti-pdp').parent().find('.icon-alert').addClass('uk-hidden');
                    _$('.product-button-add .add-quantity-button:last-child').prop('disabled', true);
                    _$('#button-cart-add').prop('disabled', true);
                }else if(Window.volume.cartVolume >= Window.volume.maxVolumeNoSurcharge){
                    this.hideAlertWarningPdp();
                    _$('.alert-contingency-product').addClass('uk-hidden');
                    this.showHeavyWarningPdp('.limite-prodotti-pdp', '.troppi-prodotti-pdp');
                    _$('.limite-prodotti-pdp').parent().find('.icon-alert').removeClass('uk-hidden');
                    _$('.limite-prodotti-pdp').parent().find('.icon-weight').addClass('uk-hidden');
                    _$('.product-button-add .add-quantity-button:last-child').prop('disabled', false);
                    _$('#button-cart-add').prop('disabled', false);
                }else{
                    this.hideAlertWarningPdp();
                    _$('.product-button-add .add-quantity-button:last-child').prop('disabled', false);
                    _$('#button-cart-add').prop('disabled', false);
                }
            }

            if(Window.displayedProduct && Window.displayedProduct.increment && _$('#preparationNotesBox').length && Window._cartQuantity){
                _$('#preparationNotesBox').removeClass('uk-hidden');
                _$('.product-preparation-notes').find('.filter').prop('disabled', false);
                _$('.product-preparation-notes').find('.filter').removeClass('uk-disabled showAlert');
                _$('.alert-variable-product').addClass('uk-hidden');
            }

            if(Window.displayedProduct && Window.displayedProduct.variations && _$('#preparationNotesBox').length){
                this.variableProductPreparationNotes();
            }
        }
    }

    showHeavyWarningPdp(classShow: string, classHide: string){
        _$('.alert-heavy-product').removeClass('uk-hidden');
        _$(classHide).addClass('uk-hidden');
        _$(classShow).removeClass('uk-hidden');
    }

    hideAlertWarningPdp(){
        _$('.alert-heavy-product').addClass('uk-hidden');
    }

    updateRemovedProductOnPage(productType: string, productCode: string, scale?: string, cartEntries?: object){
        /* product cards */
        
        if(_$('.component-ProductCard[data-code='+productCode+']').length > 0){
            _$('.component-ProductCard[data-code='+productCode+']').each((index,element) => {
                switch(productType){
                    case 'variable':
                        if(_$(element).attr('data-quantity') && _$(element).attr('data-entryNumber') && _$(element).attr('data-product')){
                            let dataQuantity = JSON.parse(_$(element).attr('data-quantity'));
                            let dataEntryNumber = JSON.parse(_$(element).attr('data-entryNumber'));
                            let dataProduct = JSON.parse(_$(element).attr('data-product'));
                            if(dataQuantity[scale]){
                                delete dataQuantity[scale];
                            }
                            if(dataEntryNumber[scale] && dataEntryNumber.length < 2){
                                dataEntryNumber = {}
                            }else{
                                delete dataEntryNumber[scale];
                            }

                            _$(element).attr('data-quantity', JSON.stringify(dataQuantity));
                            _$(element).attr('data-entryNumber', JSON.stringify(dataEntryNumber));

                            /* Pezzature svuotate */
                            if(Object.keys(dataQuantity).length < 1){
                                _$(element).find('.alert-add-to-cart').addClass('uk-hidden');
                                _$(element).find('.add-quantity').find('.quantity').html('0 '+ this.scaleMap[JSON.parse(_$(element).attr('data-product')).variations.unitOfMeasure]);
                                _$(element).find('.add-quantity').find('.price').html('€ 0,00');
                                _$(element).find('.add-product').removeClass('uk-hidden');
                                _$(element).find('.add-quantity').addClass('uk-hidden');
                            }else{
                                let currentPrice = 0;
                                let totalQty = 0;
                                let entries = cartEntries;
                                for(let entry in entries){
                                    if(entries[entry].productCode == JSON.parse(_$(element).attr('data-product')).code){
                                        currentPrice += entries[entry].totalPrice.value;
                                        totalQty += entries[entry].quantity * 1000 * entries[entry].scale;
                                    }
                                }

                                totalQty = totalQty / 1000;

                                if(dataProduct.variations && dataProduct.variations.unitOfMeasure == "KG"){
                                    _$(element).find('.add-quantity').find('.quantity').html((totalQty < 1 ? parseFloat((totalQty * 1000).toFixed(4)).toString().replace('.',',') : parseFloat((totalQty).toFixed(4))).toString()+ ' ' + (totalQty < 1 ? this.scaleMap["GR"] : this.scaleMap["KG"]));
                                }else if(dataProduct.variations && dataProduct.variations.unitOfMeasure == "GR"){
                                    _$(element).find('.add-quantity').find('.quantity').html((totalQty >= 1000 ? (totalQty/1000).toString().replace('.',',') : totalQty).toString()+ ' ' + (totalQty >= 1000 ? this.scaleMap["KG"] : this.scaleMap["GR"]));
                                }else{
                                    _$(element).find('.add-quantity').find('.quantity').html((totalQty >= 1000 ? (totalQty/1000).toString().replace('.',',') : totalQty).toString()+ ' ' + (totalQty >= 1000 ? this.scaleMap["KG"] : this.scaleMap["GR"]));
                                }
                                _$(element).find('.add-quantity').find('.price').html('€ ' + currentPrice.toFixed(2).toString().replace('.', ','));
                            }
                            
                        }
                        break;
                    case 'incremental':
                        if(_$(element).attr('data-quantity') && _$(element).attr('data-scale')){
                            _$(element).attr('data-quantity', 0);
                            _$(element).attr('data-scale', 0);
                            _$(element).removeAttr('data-entryNumber');

                            _$(element).find('.add-product').removeClass('uk-hidden');
                            _$(element).find('.add-quantity').addClass('uk-hidden');
                        }

                        break;
                    default:
                        if(_$(element).attr('data-quantity') && _$(element).attr('data-entryNumber')){
                            _$(element).attr('data-quantity', 0);
                            _$(element).removeAttr('data-entryNumber');
                            _$(element).find('.add-product').removeClass('uk-hidden');
                            _$(element).find('.add-quantity').addClass('uk-hidden');
                        }
                        break;
                }

                if(_$(element).find('.alert-add-to-cart')){
                    _$(element).find('.alert-add-to-cart').addClass('uk-hidden');
                }
            });
        }
        /* cards suggest */
        if(_$('.component-CardSuggest[data-code='+productCode+']').length > 0){
            _$('.component-CardSuggest[data-code='+productCode+']').each((index,element) => {
                switch(productType){
                    case 'variable':
                        if(_$(element).attr('data-quantity') && _$(element).attr('data-entryNumber')){
                            let dataQuantity = JSON.parse(_$(element).attr('data-quantity'));
                            let dataEntryNumber = JSON.parse(_$(element).attr('data-entryNumber'));
                            if(dataQuantity[scale]){
                                delete dataQuantity[scale];
                            }
                            if(dataEntryNumber[scale] && dataEntryNumber.length < 2){
                                dataEntryNumber = {}
                            }else{
                                delete dataEntryNumber[scale];
                            }

                            _$(element).attr('data-quantity', JSON.stringify(dataQuantity));
                            _$(element).attr('data-entryNumber', JSON.stringify(dataEntryNumber));
                            _$(element).find('.add-cart .uk-badge').addClass('uk-hidden');
                            _$(element).find('.add-cart').prop('disabled', false);
                        }
                        break;
                    case 'incremental':
                        if(_$(element).attr('data-quantity') && _$(element).attr('data-scale')){
                            _$(element).attr('data-quantity', 0);
                            _$(element).attr('data-scale', 0);
                            _$(element).removeAttr('data-entryNumber');
                            _$(element).find('.add-cart .uk-badge').addClass('uk-hidden');
                            _$(element).find('.add-cart').prop('disabled', false);
                        }

                        break;
                    default:
                        if(_$(element).attr('data-quantity') && _$(element).attr('data-entryNumber')){
                            _$(element).attr('data-quantity', 0);
                            _$(element).removeAttr('data-entryNumber');
                            _$(element).find('.add-cart .uk-badge').addClass('uk-hidden');
                            _$(element).find('.add-cart').prop('disabled', false);
                        }
                        break;
                }
            });
        }
        /* last view */
        if(_$('.component-ProductCardLastView[data-code='+productCode+']').length > 0){
            _$('.component-ProductCardLastView[data-code='+productCode+']').each((index,element) => {
                switch(productType){
                    case 'variable':
                        if(_$(element).attr('data-quantity') && _$(element).attr('data-entryNumber')){
                            let dataQuantity = JSON.parse(_$(element).attr('data-quantity'));
                            let dataEntryNumber = JSON.parse(_$(element).attr('data-entryNumber'));
                            if(dataQuantity[scale]){
                                delete dataQuantity[scale];
                            }
                            if(dataEntryNumber[scale] && dataEntryNumber.length < 2){
                                dataEntryNumber = {}
                            }else{
                                delete dataEntryNumber[scale];
                            }

                            _$(element).attr('data-quantity', JSON.stringify(dataQuantity));
                            _$(element).attr('data-entryNumber', JSON.stringify(dataEntryNumber));
                            _$(element).find('.add-cart .uk-badge').addClass('uk-hidden');
                            _$(element).find('.add-cart').prop('disabled', false);
                        }
                        break;
                    case 'incremental':
                        if(_$(element).attr('data-quantity') && _$(element).attr('data-scale')){
                            _$(element).attr('data-quantity', 0);
                            _$(element).attr('data-scale', 0);
                            _$(element).removeAttr('data-entryNumber');
                            _$(element).find('.add-cart .uk-badge').addClass('uk-hidden');
                            _$(element).find('.add-cart').prop('disabled', false);
                        }

                        break;
                    default:
                        if(_$(element).attr('data-quantity') && _$(element).attr('data-entryNumber')){
                            _$(element).attr('data-quantity', 0);
                            _$(element).removeAttr('data-entryNumber');
                            _$(element).find('.add-cart .uk-badge').addClass('uk-hidden');
                            _$(element).find('.add-cart').prop('disabled', false);
                        }
                        break;
                }
            });
        }
        /* dettaglio prodotto */
        if(_$('body').hasClass('page-DetailProduct')){
            if(Window.displayedProduct && Window.displayedProduct.code == productCode){
                switch(productType){
                    case 'variable':
                        if(Window._cartQuantity != undefined && Window._cartEntryNumber != undefined){
                            let dataQuantity = Window._cartQuantity;
                            let dataEntryNumber = Window._cartEntryNumber;
                            let dataPrepNotes = Window._cartPrepNote;
                            if(dataQuantity[scale]){
                                delete dataQuantity[scale];
                            }

                            if(dataEntryNumber[scale] && dataEntryNumber.length < 2){
                                dataEntryNumber = {}
                            }else{
                                delete dataEntryNumber[scale];
                            }

                            if(dataPrepNotes && dataPrepNotes[scale]){
                                delete dataPrepNotes[scale];
                            }

                            Window._cartQuantity = dataQuantity;
                            Window._cartEntryNumber = dataEntryNumber;
                            Window._cartPrepNote = dataPrepNotes;

                            /* Carrello svuotato - nessuna pezzatura */
                            if(Object.keys(Window._cartQuantity).length < 1){
                                _$('.product-header .product-button-add .button-cart-add').removeClass('uk-hidden');
                                _$('.product-header .product-button-add .add-quantity').addClass('uk-hidden');

                                
                            }else{
                                let currentPrice = 0;
                                let totalQty = 0;
                                let entries = cartEntries;
                                for(let entry in entries){
                                    if(entries[entry].productCode == Window.displayedProduct.code){
                                        currentPrice += entries[entry].totalPrice.value;
                                        totalQty += entries[entry].quantity * 1000 * entries[entry].scale;
                                    }
                                }

                                totalQty = totalQty / 1000;

                                if(Window.displayedProduct.variations && Window.displayedProduct.variations.unitOfMeasure == "KG"){
                                    _$('.product-button-add').find('.quantity').html((totalQty < 1 ? this.parseScaleKgToGr(totalQty * 1000) : parseFloat((totalQty).toFixed(4))).toString()+ ' ' + (totalQty < 1 ? this.scaleMap["GR"] : this.scaleMap["KG"]));
                                }else if(Window.displayedProduct.variations && Window.displayedProduct.variations.unitOfMeasure == "GR"){
                                    _$('.product-button-add').find('.quantity').html((totalQty >= 1000 ? totalQty/1000 : totalQty).toString()+ ' ' + (totalQty >= 1000 ? this.scaleMap["KG"] : this.scaleMap["GR"]));
                                }else{
                                    _$('.product-button-add').find('.quantity').html((totalQty >= 1000 ? totalQty/1000 : totalQty).toString()+ ' ' + (totalQty >= 1000 ? this.scaleMap["KG"] : this.scaleMap["GR"]));
                                }

                                _$('.product-button-add').find('.price').html('€ ' + currentPrice.toFixed(2).toString().replace('.', ',')+'€');
    
                                if(Window.displayedProduct.variations.unitWeight){
                                    let pz = Math.floor(totalQty / Window.displayedProduct.variations.unitWeight);
                                    _$('.alert-promo p b').html(`La quantità aggiunta corrisponde a ca. ${pz} pezzi`);
                                    _$('.alert-promo').removeClass('uk-hidden');
                                }
                            }

                            this.variableProductPreparationNotes();
                        }
                        break;
                    case 'incremental':
                        if(Window._cartQuantity > -1 && Window._cartScale > -1){
                            Window._cartQuantity = 0;
                            Window._cartScale = 0;
                            delete Window._cartEntryNumber;
                            _$('.product-header .product-button-add .button-cart-add').removeClass('uk-hidden');
                            _$('.product-header .product-button-add .add-quantity').addClass('uk-hidden');
                        }

                        break;
                    default:
                        if(Window._cartQuantity > -1){
                            Window._cartQuantity = 0;
                            delete Window._cartEntryNumber;
                            _$('.product-header .product-button-add .button-cart-add').removeClass('uk-hidden');
                            _$('.product-header .product-button-add .add-quantity').addClass('uk-hidden');
                        }
                        break;
                }

                if(_$('.product-header .alert-promo')){
                    _$('.product-header .alert-promo').addClass('uk-hidden');
                }
            }
        }

        if(_$('body').hasClass('page-DetailCart')){
            _$('.page-DetailCart').find('primary-panel').trigger('reloadCart');
        }

    }

    /* gestione box nota di prep per peso variabile */
    variableProductPreparationNotes(){
        /* se rimane una singola entry bisogna mostrare le note di preparazione */
        if(_$('#preparationNotesBox').length){
            if(Object.keys(Window._cartQuantity).length == 1){
                _$('#preparationNotesBox').removeClass('uk-hidden');
                _$('.product-preparation-notes').find('.filter').prop('disabled', false);
                _$('.product-preparation-notes').find('.filter').removeClass('uk-disabled showAlert');
                _$('.alert-variable-product').addClass('uk-hidden');
                let size = Object.keys(Window._cartQuantity)[0];
                let entryNumber = Window._cartEntryNumber[size];
                let qty = Window._cartQuantity[size];
                
                /* Settare il last modified item come l'ultima entry rimasta a carrello */
                this.pdpLastModifiedItem['entryNumber'] = entryNumber;
                this.pdpLastModifiedItem['qty'] = qty;
                this.pdpLastModifiedItem['productId'] =  Window.displayedProduct.code;
                this.pdpLastModifiedItem['scale'] =  parseFloat(size) ? parseFloat(size) : size;
                
            }else if(Object.keys(Window._cartQuantity).length > 1){
                _$('#preparationNotesBox').addClass('uk-hidden');
                _$('.product-preparation-notes').find('.filter').prop('disabled', true);
                _$('.product-preparation-notes').find('.filter').addClass('uk-disabled showAlert');
                _$('.alert-variable-product').removeClass('uk-hidden');
            }else{
                _$('#preparationNotesBox').addClass('uk-hidden');
                _$('.alert-variable-product').addClass('uk-hidden');
                this.pdpLastModifiedItem = {};
            }
        }else{
            console.log('no preparation note');
        }
    }

    /* Nel caso di lowStock, disabilitare le CTA + delle card prodotto */
    manageLowStock(productCode, disabled: boolean){
        if(_$('.component-ProductCardLastView[data-code='+productCode+']').length > 0){
            _$('.component-ProductCardLastView[data-code='+productCode+']').find('.add-cart').prop('disabled', disabled);
            if(disabled){
                _$('.component-ProductCardLastView[data-code='+productCode+']').find('.alert-out-of-stock').removeClass('uk-hidden');
            }else{
                _$('.component-ProductCardLastView[data-code='+productCode+']').find('.alert-out-of-stock').addClass('uk-hidden');
            }
        }

        if(_$('.component-ProductCard[data-code='+productCode+']').length > 0){
            _$('.component-ProductCard[data-code='+productCode+']').find('.add-quantity .add-quantity-button:last-child').prop('disabled', disabled);
            _$('.component-ProductCard[data-code='+productCode+']').find('.add-product').prop('disabled', disabled);
            if(disabled){
                _$('.component-ProductCard[data-code='+productCode+']').find('.alert-out-of-stock').removeClass('uk-hidden');
            }else{
                _$('.component-ProductCard[data-code='+productCode+']').find('.alert-out-of-stock').addClass('uk-hidden');
            }
        }

        if(_$('.component-CardSuggest[data-code='+productCode+']').length > 0){
            _$('.component-CardSuggest[data-code='+productCode+']').find('.add-cart').prop('disabled', disabled);

            if(disabled){
                _$('.component-CardSuggest[data-code='+productCode+']').find('.alert-out-of-stock').removeClass('uk-hidden');
            }else{
                _$('.component-CardSuggest[data-code='+productCode+']').find('.alert-out-of-stock').addClass('uk-hidden');
            }
        }

        if(_$('body').hasClass('page-DetailProduct') && Window.displayedProduct.code == productCode){
            _$('.product-button-add .add-quantity-button:last-child').prop('disabled', disabled);
            _$('#button-cart-add').prop('disabled', disabled);
            _$('#fixed-button-add-cart').prop('disabled', disabled);
            if(disabled){
                _$('.page-DetailProduct .product-header').find('.alert-out-of-stock').removeClass('uk-hidden');
            }else{
                _$('.page-DetailProduct .product-header').find('.alert-out-of-stock').addClass('uk-hidden');
            }
        }
    }

    updateEntryNumbers(page: object, entries: string){
        if(entries.length > 0){
            let entryCodes = {};
            let cartEntries = JSON.parse(entries);
            for(let i = 0; i < cartEntries.length; i++){
                /* Nel caso sia peso variabile */
                if(cartEntries[i].scale){
                    if(!entryCodes[cartEntries[i].productCode]){
                        let scaleEntry = {}
                        scaleEntry[cartEntries[i].scale] = cartEntries[i].entryNumber;
                        entryCodes[cartEntries[i].productCode] = scaleEntry;
                    }else{
                        let values = entryCodes[cartEntries[i].productCode];
                        values[cartEntries[i].scale] = cartEntries[i].entryNumber;
                        entryCodes[cartEntries[i].productCode] = values;
                    }
                }else{
                    entryCodes[cartEntries[i].productCode] = cartEntries[i].entryNumber;
                }
            }
            
            /* Aggiornamento entrynumber per le product card */
            
            _$('.component-ProductCard[data-entryNumber]').each(function(){
                let card = _$(this);
                if(entryCodes[JSON.parse(card.attr('data-product')).code] ==  undefined){
                    _$(this).attr('data-entryNumber', '{}');
                }else{
                    _$(this).attr('data-entryNumber', JSON.stringify(entryCodes[JSON.parse(card.attr('data-product')).code]));
                }
            });

            _$('.component-ProductCardDetailCart[data-entryNumber]').each(function(){
                let card = _$(this);
                if(entryCodes[JSON.parse(card.attr('data-product')).code] ==  undefined){
                    _$(this).attr('data-entryNumber', '{}');
                }else{
                    _$(this).attr('data-entryNumber', JSON.stringify(entryCodes[JSON.parse(card.attr('data-product')).code]));
                }
            });

            /* Se siamo nel dettaglio prodotto bisogna aggiornare anche il displayedProduct */
            if(_$(page).hasClass('page-DetailProduct')){
                Window._cartEntryNumber = entryCodes[Window.displayedProduct.code];
            }
        }
    }

    /**
     * Aggiorna, mostra, nasconde il btn-cart nell'header
     * @param totalPrice
     * @param totalUnits
     */
    updateCartBtn(totalPrice, totalUnits) {
        let containerEmptyCart = _$('.component-header').find('.container-cart-empty');
        let containerFullCart = _$('.component-header').find('.container-cart-full');
        if (totalUnits > 0) {
            containerEmptyCart.addClass('uk-hidden');
            containerFullCart.removeClass('uk-hidden');
            containerFullCart.find('.btn-cart').attr('aria-live', 'polite');
            if(totalPrice.toFixed(2) && totalPrice.toFixed(2).toString() && totalPrice.toFixed(2).toString().replace('.',',')){
                containerFullCart.find('.btn-cart span.text').html(`${totalPrice.toFixed(2).toString().replace('.',',')} €&nbsp;&nbsp;-&nbsp;&nbsp;[${totalUnits}]`);
                containerFullCart.find('.btn-cart.uk-visible\\@l-2').attr('aria-label', `Carrello ${totalPrice.toFixed(2).toString().replace('.',',')} €, ${totalUnits} prodotti`);
            }
            containerFullCart.find('.btn-cart span.uk-badge').html(`${totalUnits}`);
            containerFullCart.find('.btn-cart.uk-hidden\\@l-2').attr('aria-label', `Carrello ${totalUnits} prodotti`);
        } else {
            containerEmptyCart.removeClass('uk-hidden');
            containerFullCart.addClass('uk-hidden');
        }
    }

    /**
     * Chiamata per tracciamenti LSF
     * @param action
     * @param prodCode
     * @param searchQuery
     */
    callLsfTrack(action, prodCode, searchQuery) {
        let success = (res) => {
            console.log('success lsf');
        };

        let error = (err) => {
            console.log('error lsf');
        };
        let body;

        if (Window.user) {
            body = {
                events: [
                    {
                        user_id: Window.user.utenzaId,
                        query: searchQuery,
                        prod_code: prodCode,
                        action: action,
                    },
                ],
            };
        } else {
            body = {
                events: [
                    {
                        query: searchQuery,
                        prod_code: prodCode,
                        action: action,
                    },
                ],
            };
        }

        this._lsfService.lsfTrack(success, error, body);
    }

    /**
     * Check the numeric input only
     * @param element
     */
    // checkInputNumber(element) {
    //     let input = _$(element);
    //     // @ts-ignore
    //     input.val(input.val().replace(/\D/g, ""));
    // }


    //***** Scripts Chatbox Salesforce
    addRoleChatbox() {
        _$('#embedded-service').attr('role', 'dialog').attr('aria-label', 'Servizio Clienti Chat');
    }
    showIESnackBar() {
        var x = document.getElementById("IESnackbar");
        x.className = "show";
        setTimeout(function () {
            x.className = x.className.replace("show", "");
        }, 6000);
    }
    showFirefoxSnackBar() {
        var x = document.getElementById("firefoxSnackbar");
        x.className = "show";
        setTimeout(function () {
            x.className = x.className.replace("show", "");
        }, 6000);
    }
    detectIEVersion() {
        // detect IE10 or older
        //console.log('detectIE10 Or Older');
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");
        if (msie > 0) {
            // IE 10 or older => return version number
            console.log("IE version: " + parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10));
            return true;
        } else {
            //console.log('Not IE10 Or Older');
        }

        // detect IE11
        //console.log('detectIE11');
        var ua = window.navigator.userAgent;
        var trident = ua.indexOf("Trident/");
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf("rv:");
            console.log("IE11 version: " + parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10));
            return true;
        } else {
            //console.log('Not IE11');
        }
    }
    // Check if Firefox is running in private mode
    isPrivateMode() {
        return new Promise(function detect(resolve) {
            var yes = function () {
                resolve(true);
            }; // is in private mode
            var not = function () {
                resolve(false);
            }; // not in private mod

            function isFirefoxPrivateMode() {
                var isMozillaFirefox = "MozAppearance" in document.documentElement.style;
                if (isMozillaFirefox) {
                    if (indexedDB == null) yes();
                    else {
                        var db = indexedDB.open("inPrivate");
                        db.onsuccess = not;
                        db.onerror = yes;
                    }
                }
                return isMozillaFirefox;
            }

            if (isFirefoxPrivateMode()) return;
            return not();
        });
    }
    listenClickOnBubble() {
        var bubble = document.getElementById("esw-fab");
        //console.log('bubble: ' , bubble);
        if (bubble != null) {
            bubble.addEventListener("click", this.showIESnackBar);
            clearInterval(this.myInterval);
        }
    }
    setInterval_listenClickOnBubble() {
        this.myInterval = setInterval(this.listenClickOnBubble, 500);
    }
    handlerButtonChat() {
        _$('#esw-fab').trigger('click');
    }


    //***** Modals Generic

    /**
     * send Analytics Data
     * @param popupType
     * @param eventType
     */
    sendAnalyticsGenericModal(popupType, eventType) {
        try {
            let analyticsData;

            if (popupType == 'servizioNonDisponibile') {
                analyticsData = {
                    event: eventType,
                    funnel: {
                        stepFunnel: `tbd`,
                        tipoServizio: Window.typeOfService ? Window.typeOfService : '',
                        sceltaNegozio: {
                            idNegozio: Window.pointOfService ? Window.pointOfService.name : '',
                            cooperativaNegozio: Window.pointOfService ? Window.pointOfService.cooperativeId : '',
                            idNegozioBackup: 'tbd',
                        },
                        sceltaSlot: {},
                    },
                };
            } else {
                analyticsData = {
                    event: eventType,
                    funnel: {
                        stepFunnel: `Popup ${popupType}`,
                        provenienza: 'tbd',
                        tipoServizio: Window.typeOfService ? Window.typeOfService : '',
                        sceltaNegozio: {
                            idNegozio: Window.pointOfService ? Window.pointOfService.name : '',
                            cooperativaNegozio: Window.pointOfService ? Window.pointOfService.cooperativeId : '',
                            idNegozioBackup: 'tbd',
                        },
                        sceltaSlot: {},
                    },
                };
            }
    
            analyticsData.funnel.sceltaSlot = this._analytics.manageSceltaSlot();
            /* Check se siamo nell'entrypage */
            if (_$('.modal-generic').closest('.page-entrypage')) {
                this._analytics.sendAnalytics(analyticsData, 'FUNNEL_DATA_EP_NO_STEP', true, false);
            }
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }

    sendAnalyticsBlacklistModal(eventType) {
        try {
            let analyticsData;
            let funnelDict;

            if (eventType == 'pageview') {
                //5.1.1
                analyticsData = {
                    event: eventType
                };

                funnelDict = 'FUNNEL_DATA_BANNER_BLACKLIST';
            } else if (eventType == 'clickContattaciBannerBlacklist') {
                //5.1.2
                analyticsData = {
                    event: eventType
                };

                funnelDict = 'FUNNEL_DATA_BANNER_BLACKLIST_CLICK_CONTATTACI';                
            }
    
            this._analytics.sendAnalytics(analyticsData, funnelDict, true, false);
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }

    /**
     *  Tracciamenti modal generic
     */
    trackingOnShowGenericModal() {
        _$('#modalInterruzioneModifica').on('show', () => {
            this.sendAnalyticsGenericModal('interruzioneModifica', 'pageview');
        });

        _$('#modalServizioNonDisponibile').on('show', () => {
            this.sendAnalyticsGenericModal('servizioNonDisponibile', 'pageview');
        });
    }

    /**
     * Gestione chiusura multipla modal/offcanvas
     */
    closeDoubleOffcanvas(el: string, parent: string) {
        _Uikit.modal(el).hide();
        _Uikit.offcanvas(parent).hide();
    }

    //***** Modals Slot Prenotato Scadenza

    /**
     * Controllo Timer slot
     */
    /* Timer da utilizzare per far scattare i popup */
    /* Se il servizio è disponibile i timer vengono settati, altrimenti verrà gestito il popup di servizio indisponibile */
    checkTimerSlotPrenotatoScadenza() {
        if(Window.typeOfService && Window.typeOfService == "HOME_DELIVERY" && Window.pointOfService && !Window.pointOfService.hdTemporaryUnavailable ||
            Window.typeOfService && Window.typeOfService == "ORDER_AND_COLLECT" && Window.pointOfService && !Window.pointOfService.ocTemporaryUnavailable
            ){
                if (Window.selectedTimeslotExpiration) {
                    let expDate = new Date(Window.selectedTimeslotExpiration);
                    let currentDate = new Date();
                    currentDate.setTime(currentDate.getTime() - currentDate.getTimezoneOffset() * 60 * 1000);
                    expDate.setTime(expDate.getTime() - expDate.getTimezoneOffset() * 60 * 1000);
                    
                    let timer = expDate.getTime() - currentDate.getTime() > 0 ? expDate.getTime() - currentDate.getTime() : 0;
                    if(_$('#warning-first').length && _$('#warning-last').length && _$('#warning-slot-expired').length){
                        _Uikit.countdown(_$('#warning-first .countdown-timer'), {
                            date: expDate.toISOString(),
                        });
            
                        _Uikit.countdown(_$('#warning-last .countdown-timer'), {
                            date: expDate.toISOString(),
                        });
            
                        let firstWarningMins = parseInt(_$('#warning-first').attr('data-minutes'));
                        let lastWarningMins = parseInt(_$('#warning-last').attr('data-minutes'));
                        
                        if(this._localStorage.getFromStorage('noMoreExtend')){
                            if(!_$('body').hasClass('page-CheckoutNew')){
                                this.timeSlotExpiredHandler = setTimeout(() => {
                                    _Uikit.modal(_$('#warning-slot-expired')).show();
                                }, timer);
                            }
                        }else{
                            let timeWarningFirst = (timer - firstWarningMins * 60 * 1000) > 0 ? (timer - firstWarningMins * 60 * 1000) : 0;
                            let timeWarningLast = (timer - lastWarningMins * 60 * 1000) > 0 ? (timer - lastWarningMins * 60 * 1000) : 0;
                            
                            
                            let manageWarningFirst = () => {
                                this.timeWarningFirstHandler = setTimeout(() => {
                                    _$('#warning-first').removeClass('uk-hidden');
                                }, timeWarningFirst);
                            }
                            
                            let manageWarningLast = () => {
                                this.timeWarningLastHandler = setTimeout(() => {
                                    _$('#warning-first').addClass('uk-hidden');
                                    _Uikit.modal(_$('#warning-last')).show();
                                }, timeWarningLast);
                            }

                            let manageWarningExpired = () => {
                                if(!_$('body').hasClass('page-CheckoutNew')){
                                    
                                    this.timeSlotExpiredHandler = setTimeout(() => {
                                        _Uikit.modal(_$('#warning-last')).hide();
                                        _Uikit.modal(_$('#warning-slot-expired')).show();
                                    }, timer);
                                }else{
                                    this.timeSlotExpiredHandler = setTimeout(() => {
                                        _Uikit.modal(_$('#warning-last')).hide();
                                        _Uikit.modal(_$('#warning-slot-expired-checkout')).show();
                                    }, timer);
                                }
                            }

                            if(timer == 0){
                                manageWarningExpired();
                            }else if(timeWarningLast == 0){
                                manageWarningLast();
                                manageWarningExpired();
                            }else{
                                manageWarningFirst();
                                manageWarningLast();
                                manageWarningExpired();
                            }
                        }
                    }
                }
        }
    }
    /**
     * send Analytics Data
     * @param eventType
     * @param trigger
     */
    sendAnalyticsSlotPrenotatoScadenza(eventType: string, trigger: string) {
        try {
            let analyticsData;
            let funnel;
    
            analyticsData = {
                event: eventType,
            };
            if(eventType == 'sessioneChiudi'){
                this._localStorage.setItem('noMoreExtend', 'true');
            }
            if (trigger == 'infobar') {
                switch (eventType){
                    case 'sessioneEstendi':
                        funnel = 'FUNNEL_DATA_EST_SES_INFOBAR_EST';
                        break;
                    case 'sessioneChiudi':
                        funnel = 'FUNNEL_DATA_EST_SES_INFOBAR_CLOSE';
                        break;
                }
            }
    
            if (trigger == 'popup') {
                switch (eventType){
                    case 'sessioneEstendi':
                        funnel = 'FUNNEL_DATA_EST_SES_POPUP_EST';
                        break;
                    case 'sessioneChiudi':
                        funnel = 'FUNNEL_DATA_EST_SES_POPUP_CLOSE';
                        break;
                    case 'sessioneConferma':
                        funnel = 'FUNNEL_DATA_EST_SES_POPUP_CONF';
                        break;
                    case 'sessioneConfermaChiudi':
                        funnel = 'FUNNEL_DATA_EST_SES_POPUP_CONF_CLOSE';
                        break;
                    case 'ctaNuovaSessione':
                        funnel = 'FUNNEL_DATA_EST_SES_POPUP_NEW_SESSION';
                        break;
                    case 'ignoraNuovaSessione':
                        funnel = 'FUNNEL_DATA_EST_SES_POPUP_NEW_SESSION_IGNORE';
                        break;
                }
            }
    
            this._analytics.sendAnalytics(analyticsData, funnel);
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }

    /** FASE 2 - Extend Slot
     * Estendo lo slot Prenotato (chiamata sugli onclick)
     * @param e
     * @param trigger
     */
    slotPrenotatoScadenzaExtend(e: object, trigger: string) {
        this.showLoader(_$(e).closest('.component-ModalSlotPrenotatoScadenza').find('button.action'), true, LOADER_POSITION.BUTTON);

        let success = (res) => {
            if (res) {
                this.showLoader(_$(e).closest('.component-ModalSlotPrenotatoScadenza').find('button.action'), false, LOADER_POSITION.BUTTON);

                _$(e).closest('.component-ModalSlotPrenotatoScadenza').css( 'display', 'none');

                if (_$(e).parents('.component-ModalSlotPrenotatoScadenza').hasClass('uk-alert')) {
                    _$(e).parents('.component-ModalSlotPrenotatoScadenza').addClass('uk-hidden');
                } else {
                    _Uikit.modal(_$(e).parents('.component-ModalSlotPrenotatoScadenza')).hide();
                }
                _$('.slot-extend-alert').removeClass('uk-hidden');

                setTimeout(function () {
                    _$('.slot-extend-alert').addClass('uk-hidden');
                }, 15000);

                window.clearTimeout(this.timeWarningFirstHandler);
                window.clearTimeout(this.timeWarningLastHandler);
                window.clearTimeout(this.timeSlotExpiredHandler);

                Window.selectedTimeslotExpiration = res.data.data.expiration;
                this.checkTimerSlotPrenotatoScadenza();
                this.sendAnalyticsSlotPrenotatoScadenza('sessioneEstendi', trigger);

                if(_$('.banner-timer-container .countdown-timer').length){
                    _Uikit.countdown('.banner-timer-container .countdown-timer', {
                        date: new Date(res.data.data.expiration)
                    });
                }
            }
        };
        let error = (err) => {
            console.log('extendTimeslot error: ', err);
            this.showLoader(_$(e).closest('.component-ModalSlotPrenotatoScadenza').find('button.action'), false, LOADER_POSITION.BUTTON);
        };
        let body = {};
        this.storeService.extendTimeslot(success, error, body);
    }

    /**
     * Inserisce i valori della select nel campo select (div)
     * @param field 
     * @param data 
     */
     searchOptionValue(formId, field, data) {
         /* TODO: da sistemare il reset della select */
        let form = _$('#' + formId);
        if(data == 'reset'){
            form.find(`${field}`).val('');
        }else{
            form.find(`${field}`).val(data)
        }
    }

    /**
     * Zoom image
     * @param container
     * @param id
     * @param img
     */
    initZoom(container: string, id: string, img?) {
        if(img) {
            let srcImg = _$(img).attr('src');
            _$('#' + container).attr('style', '').html(`<img id=${id} class=${id} data-NZoomscale="4.5" src="${srcImg}" alt="Immagine Prodotto" />`);
        }
        let t = document.getElementById(id);
        setTimeout(() => {
            // @ts-ignore
            let e = t.getAttribute("data-NZoomscale") <= 0 ? 1 : t.getAttribute("data-NZoomscale"),
                s = t.clientWidth, o = t.clientHeight;
            _$("#" + id).replaceWith(t.outerHTML);
            let i = _$("#" + container), n = _$("#" + id);
            i.css("width", s + "px"), i.css("height", o + "px"), i.mousemove(function(t) {
                    let e = _$(this).offset(),
                        i = (t.pageX - e.left) / s * 100 <= 100 ? (t.pageX - e.left) / s * 100 : 100,
                        c = (t.pageY - e.top) / o * 100 <= 100 ? (t.pageY - e.top) / o * 100 : 100;
                    n.css("transform-origin", i + "% " + c + "%")
                }), i.mouseenter(function() {
                n.css("cursor", "crosshair"), n.css("width", s + "px"), n.css("height", o + "px"), n.css("transition", "0.2s"), n.css("transform", "scale(" + e + ")")
            }).mouseleave(function() {
                n.css("transition", "0.2s"), n.css("transform", "scale(1)")
            })
        }, 500);
    }

    getDayFromTimestamp(timestamp){
        var date = new Date(timestamp);
        return date;
    }


    // SWIPER CAROUSEL CUSTOM FUNCTIONS

    mySlideNext(swiperOptions, swiper) {
        let range;

        if (_$(window).width() < 768) {
            range = swiperOptions.breakpoints['375'].slidesPerView;
        } else if (_$(window).width() < 1024){
            range = swiperOptions.breakpoints['768'].slidesPerView;
        } else if (_$(window).width() < 1440){
            range = swiperOptions.breakpoints['1024'].slidesPerView;
        } else {
            range = swiperOptions.breakpoints['1440'].slidesPerView;
        }
        
        for (let i = 0; i < Math.trunc(range); i++) {
            swiper.slideNext();
        }
    }

    mySlidePrev(swiperOptions, swiper) {
        let range;
        
        if (_$(window).width() < 768) {
            range = swiperOptions.breakpoints['375'].slidesPerView;
        } else if (_$(window).width() < 1024){
            range = swiperOptions.breakpoints['768'].slidesPerView;
        } else if (_$(window).width() < 1440){
            range = swiperOptions.breakpoints['1024'].slidesPerView;
        } else {
            range = swiperOptions.breakpoints['1440'].slidesPerView;
        }
        
        for (let i = 0; i < Math.trunc(range); i++) {
            swiper.slidePrev();
        }
    }

    isInBlackList() {
        return Window.cart && Window.cart.paymentBlacklistedUser;
    }

    parseScaleKgToGr(scaleInKg){
        return parseFloat(scaleInKg.toFixed(4));
    }

    checkNxMProducts(cart: any, products: any, updatingProductCode:any): number{
        let entries = cart.entries;
        let nxmOnCart = 0;
        for(let i = 0; i < entries.length; i++){
            if(products != undefined && products.indexOf(entries[i].productCode) > -1){
                nxmOnCart += entries[i].quantity;
            }
        }

        this.updateNxMProducts(cart, products, updatingProductCode);

        return nxmOnCart;
    }


    /**
     * Aggiorna i prezzi dei prodotti facenti parte della promo NxM specifica
     * @param cart: carrello corrente 
     * @param products: prodotti facenti parte della promo NxM specifica 
     */
    updateNxMProducts(cart: any, products: any, updatingProductCode){
        let entries = cart.entries;
        for(let i = 0; i < entries.length; i++){
            if(products != undefined && products.indexOf(entries[i].productCode) > -1){

                if(_$('body').hasClass('page-DetailProduct') && Window.displayedProduct && Window.displayedProduct.code == entries[i].productCode){
                    if(entries[i].totalPrice != null && entries[i].totalPrice.value != null){
                        _$('.product-button-add').find('.price').html(entries[i].totalPrice.value.toFixed(2).toString().replace('.', ',') + '€');
                    }
                }

                if(_$('.component-ProductCard[data-code='+entries[i].productCode+']').length){
                    if(entries[i].totalPrice != null && entries[i].totalPrice.value != null){
                        _$('.component-ProductCard[data-code='+entries[i].productCode+']').find('.add-quantity').find('.price').html('€ ' + entries[i].totalPrice.value.toFixed(2).toString().replace('.', ','));
                    }
                }


                /* coppia di if per nascondere gli alert sui prodotti con cui non si ha avuto l'ultima interazione */
                if(_$('body').hasClass('page-DetailProduct') && Window.displayedProduct && Window.displayedProduct.code == entries[i].productCode && Window.displayedProduct.code != updatingProductCode ){
                    _$('.alert-promo').addClass('uk-hidden');
                }

                if(_$('.component-ProductCard[data-code='+entries[i].productCode+']').length && entries[i].productCode != updatingProductCode){
                    _$('.component-ProductCard[data-code='+entries[i].productCode+']').find('.alert-add-to-cart').addClass('uk-hidden');
                }
            }
        }
    }
    
    randomizeCards(e) {
        let component = _$(e);
        if(component.length){
            component.each((index, carousel) => {

                let cards = _$(carousel).find('.swiper-slide');
                let container = _$(carousel).find('.swiper-wrapper');
                let shuffledCards = cards;

                for (var i = shuffledCards.length - 1; i > 0; i--) {
                    var j = Math.floor(Math.random() * (i + 1));
                    var temp = shuffledCards[i];
                    shuffledCards[i] = shuffledCards[j];
                    shuffledCards[j] = temp;
                }

                let htmlCard = '';

                container.html('');
                for (let i = 0; i < cards.length; i++) {
                    htmlCard = '<div class="swiper-slide">' + shuffledCards.eq(i).html() + '</div>';
                    container.append(htmlCard);
                }
            });
        }
    }

    clickEvent(e, event?) {
        let href = _$(e).closest('[data-href]').attr('data-href');
        if(_$(e).hasClass('banner-hotspot') && event && _$(event.target).closest('.img-container').length){
            return;
        }else{
            if(!event || (event && !_$(event.target).closest('a').length)){
                if (href) window.location.href = href;
            }
        }
    }

    checkFirstAddToCartFlag(cartData: object): string{
        let totalUnitCount = cartData['cart'] && cartData['cart'].totalUnitCount ? cartData['cart'].totalUnitCount : 0;
        let quantityAdded = cartData['modifications'] && cartData['modifications'].cartModifications && cartData['modifications'].cartModifications[0] && cartData['modifications'].cartModifications[0].quantityAdded ? cartData['modifications'].cartModifications[0].quantityAdded : 0;
        if(totalUnitCount && quantityAdded && totalUnitCount == quantityAdded){
            return JSON.stringify("SI");
        } else {
            return JSON.stringify("NO");
        }
    }

     /**
     * Aggiorna la grafica di tutti i prodotti in qualsiasi card
     * @param productCode
     * @param price: prezzo totale a carrello
     * @param quantity: quantità, pezzi o scale
     * @param show: indica se mostrare o nascondere il picker +/-
     */
    updateAllCardsPicker(productCode: string, quantity, show: boolean, price?, previewPdpVariable?:boolean, lastViewVariable?: boolean, quantityVar?, priceVar?){
        
        let allProductCards = _$('.component-ProductCard[data-code='+productCode+']');
        let allProductCardsLastView = _$('.component-ProductCardLastView[data-code='+productCode+']');
        let allCardSuggest = _$('.component-CardSuggest[data-code='+productCode+']');
        /* Se in pagina pdp gestire pure l'aggiornamento del picker pdp */

        if(allProductCards.length){
            if(show){
                _$(allProductCards).find('.add-product').addClass("uk-hidden");
                _$(allProductCards).find('.add-quantity').removeClass("uk-hidden");
            }else{
                _$(allProductCards).find('.add-product').removeClass("uk-hidden");
                _$(allProductCards).find('.add-quantity').addClass("uk-hidden");
            }

            if(lastViewVariable){
                _$(allProductCards).find('.add-quantity').find('.price').html(priceVar);
                _$(allProductCards).find('.add-quantity').find('.quantity').html(quantityVar);
            }else{
                _$(allProductCards).find('.add-quantity').find('.price').html(price);
                _$(allProductCards).find('.add-quantity').find('.quantity').html(quantity);
            }
        }

        if(allProductCardsLastView.length){
            if(show){
                allProductCardsLastView.find('.add-cart .uk-badge').removeClass('uk-hidden');
            }else{
                allProductCardsLastView.find('.add-cart .uk-badge').addClass('uk-hidden');
            }

            if(previewPdpVariable){
                if(quantityVar){
                    allProductCardsLastView.find('.add-cart .uk-badge').html(quantityVar);
                }else{
                    allProductCardsLastView.find('.add-cart .uk-badge').addClass('uk-hidden');
                }
            }else{
                allProductCardsLastView.find('.add-cart .uk-badge').html(quantity);
            }
        }

        if(allCardSuggest.length){
            if(show){
                allCardSuggest.find('.add-cart .uk-badge').removeClass('uk-hidden');
            }else{
                allCardSuggest.find('.add-cart .uk-badge').addClass('uk-hidden');
            }

            if(previewPdpVariable){
                if(quantityVar){
                    allCardSuggest.find('.add-cart .uk-badge').html(quantityVar);
                }else{
                    allCardSuggest.find('.add-cart .uk-badge').addClass('uk-hidden');
                }
            }else{
                allCardSuggest.find('.add-cart .uk-badge').html(quantity);
            }
        }

        if(Window.displayedProduct && Window.displayedProduct.code && Window.displayedProduct.code == productCode){
            if(show){
                _$('.product-button-add').find('.add-product').addClass("uk-hidden");
                _$('.product-button-add').find('.add-quantity').removeClass("uk-hidden");
            }else{
                _$('.product-button-add').find('.add-product').removeClass("uk-hidden");
                _$('.product-button-add').find('.add-quantity').addClass("uk-hidden");
            }

            if(lastViewVariable){
                _$('.product-button-add').find('.price').html(priceVar);
                _$('.product-button-add').find('.quantity').html(quantityVar);
            }else{
                _$('.product-button-add').find('.price').html(price);
                _$('.product-button-add').find('.quantity').html(quantity);
            }
        }
    }

    getVisitorId(): String{
        let visitorId;
        if(Window.s && Window.s.visitor){
            var useVisitorId = function(id){
                visitorId = id.MCMID ? id.MCMID : '';
            };
            Window.s.visitor.getVisitorValues(useVisitorId);
            
        }
        return visitorId;
    }

    /**
     * Dato un campo, effettua il focus automatico sul prossimo campo obbligatorio all'interno del form
     */
    formRequiredFieldFocus(field, event?){
        this.lastFieldAutofocus = field;
        let form = _$(field).closest('form');

        setTimeout(() => {
            if(_$(field).closest('#offcanvasFatturazioneElettronica').find('#envoiceSelection:focus').length){
            }else if(!_$(form).find('input:not(.uk-radio):focus, textarea:focus, select:focus').length){
                if (form.attr('autofocus-enabled') == 'true') {
                    if(event && event.keyCode == '13'){
                        setTimeout(() => {
                            _$(form).find('.require-autofocus-custom').filter(function() {
                                return _$(this).val().toString().trim() == "" && _$(this).prop('required');
                            }).first().trigger('focus');
                        }, 0);
                    }else if(!event){
                        setTimeout(() => {
                            _$(form).find('.require-autofocus-custom').filter(function() {
                                return _$(this).val().toString().trim() == "" && _$(this).prop('required');
                            }).first().trigger('focus');
                        }, 0)
                    }
                }
            }else{
                if (form.attr('autofocus-enabled') == 'true') {
                    if(event && event.keyCode == '13'){
                        setTimeout(() => {
                            _$(form).find('.require-autofocus-custom').filter(function() {
                                return _$(this).val().toString().trim() == "" && _$(this).prop('required');
                            }).first().trigger('focus');
                        }, 0);
                    }
                }
            }
        }, 200);
    }

    
    customAutofocusHandler(){
        let modals = _$('.uk-modal');
        let offcanvas = _$('.uk-offcanvas')
        
        /* all'apertura di qualsiasi modale vengono cancellati tutti gli attributi di autofocus */

        _Uikit.util.on('[uk-modal], [uk-offcanvas]', 'shown', () => {
            _$('[autofocus-enabled]').attr('autofocus-enabled','false');
        });
        
        if(Window.typeOfService != null && Window.typeOfService == 'HOME_DELIVERY'){
            _$('#addressForm').on('click focusin focus', () => {
                _$('#addressForm').attr('autofocus-enabled','true');
                _$('#personalForm').attr('autofocus-enabled','false');
            });

            _$('#personalForm').on('click focusin focus', () => {
                _$('#personalForm').attr('autofocus-enabled','true');
                _$('#addressForm').attr('autofocus-enabled','false');
            });

            _$('.section-codice-sconto > .uk-card-type-coupon').on('click focusin focus', () => {
                _$('#addressForm').attr('autofocus-enabled','false');
                _$('#personalForm').attr('autofocus-enabled','false');
            })
    
            /* alla chiusura di una qualsiasi modale reimpostiamo l'autofocus sul form in pagina */
            _Uikit.util.on('[uk-modal], [uk-offcanvas]', 'hidden', () => {
                _$('#addressForm, #personalForm').attr('autofocus-enabled','true');
            });
        }
        
    }

    disableAllGoogleInput(){
        _$('.location-finder').prop('disabled', true); //TODO: da rimuovere old-onboarding
        _$('.location-finder').siblings('.submitButton').prop('disabled', true); //TODO: da rimuovere old-onboarding
        _$('.google-input .google-input-line1').prop('disabled', true);
        _$('.google-input .google-input-line2').prop('disabled', true);
        _$('.google-input .submitButton').prop('disabled', true);
        this.showLoader(_$('.google-input .submitButton'), true, LOADER_POSITION.BUTTON);
    }

    enableAllGoogleInput(){
        _$('.location-finder').prop('disabled', false); //TODO: da rimuovere old-onboarding
        _$('.location-finder').siblings('.submitButton').prop('disabled', false); //TODO: da rimuovere old-onboarding
        _$('.google-input .google-input-line1').prop('disabled', false);
        _$('.google-input .google-input-line2').prop('disabled', false);
        _$('.google-input .submitButton').prop('disabled', false);
        this.showLoader(_$('.google-input .submitButton'), false, LOADER_POSITION.BUTTON);
    }

    formFindErrorFiles(formType, form): Array<Object>{
        let errorFilesName = [];
        if(formType == 'agevolazione'){
            let errorFilesLabel = _$(form).find('.allega-file .js-ag-doc-showfile .label');
            for(let i = 0; i < errorFilesLabel.length; i ++){
                if(_$(errorFilesLabel).eq(i).siblings('.error:not(.uk-hidden)').length){
                    errorFilesName.push(_$(errorFilesLabel).eq(i).text() ? _$(errorFilesLabel).eq(i).text().trim() : '');
                }
            }
            return errorFilesName;
        }else{
            return [];
        }
    }

    sendAnalytics(analyticsType, eventType){
        try{
            let link = document.location.pathname.toString().split('/');
            let step;
            let analyticsData;
            let linkProvenienza = _$('body').attr('nkpage') == 'DetailProduct' ? link[link.length - 2].split('?')[0] : link[link.length - 1].split('?')[0];
            let provenienza = this._analytics.getProvenienza(linkProvenienza);
            switch(analyticsType){
                case '1.7.2':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            provenienza: provenienza,
                        },
                    };
                    step = 'FUNNEL_DATA_EP_STEP1';
                    break;
            }

            if (analyticsType != 'chiusuraModale' && analyticsData) {
                this._analytics.sendAnalytics(analyticsData, step, true, false);
            }
        }catch(error){
            console.log(eventType + ' : ' + error);
        }
        
    }

    isTsdEnabled(): boolean{
        if(Window.pointOfService && Window.pointOfService.tsdEnabled && Window.typeOfService && Window.typeOfService == "HOME_DELIVERY"){
            return true;
        }else{
            return false;
        }
    }

    isTsdEnabledTemporaryPdv(selectedStore) : boolean {
        if(selectedStore && selectedStore.tsdEnabled && Window.typeOfService && Window.typeOfService == "HOME_DELIVERY"){
            return true;
        }else{
            return false;
        }
    }

    isNxM(dataProduct): boolean{
        let isNxM = false;
        if(dataProduct?.promo?.length){
            dataProduct.promo.forEach((element, index) => {
                console.log(element.promoType)
                if(element?.promoType == 'PRODUCT_PARTNER_MIX_FIXED_DISCOUNT'){
                    isNxM = true;
                }
            });
        }

        return isNxM;
    }
}

///////////////////////////////////////////////////
//////////// PROMISIFY EVENT
///////////////////////////////////////////////////
export const buildWaitForEvent = (eventName) => (node, func) =>
    new Promise((resolve, reject) => {
        // reject for invalid node
        if (!(node instanceof window.HTMLElement || node instanceof window.SVGElement)) {
            return reject(new Error('tail-end: an HTML or SVG element is required.'));
        }

        // create the event handler
        const handler = () => {
            // unbind the handler
            node.removeEventListener(eventName, handler);
            // resolve the (now clean) node
            return resolve(node);
        };

        // bind the handler
        node.addEventListener(eventName, handler);

        // if it exists, call the function passing in the node
        if (typeof func === 'function') {
            window.requestAnimationFrame(() => func(node));
        }
    });
